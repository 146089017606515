import React, { Component } from 'react';
import $ from 'jquery';
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import Footer from '../Footer';
import GuestFooter from '../GuestFooter';
import { MyContext } from '../../context';
import { ReactComponent as RevisitSvg } from '../../utils/svg/Revisit.svg';
import CustomPopUp from '../../CustomPopup';
import { switchAccount } from '../../Services/api';

class Settings extends React.Component {
   static contextType = MyContext
   constructor(props) {
      super(props);
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, logoutPopup: false, accountType: localStorage.getItem("profileType"), switchAccountPopup: false
      }
   }
   componentDidMount() {
      const { setActiveTab } = this.context

      if (localStorage.getItem("profileType") == "consumer") {
         localStorage.setItem("footer", "add")
         setActiveTab(() => "third")
      }
      else {
         localStorage.setItem("footer", "first");
         setActiveTab(() => "first")
      }

      $("#one_12 .errorbtn").click(() => {
         $("#one_12 .errorpop").toggle();
         this.abortLogout();
      });
   }

   changepassword() {
      if (this.state.newpassword == this.state.conformpassword) {
         const body = {
            current_password: this.state.currentpassword,
            new_password: this.state.newpassword,
            confirm_password: this.state.conformpassword
         }
         const token = localStorage.getItem('token');
         axios.post(this.state.baseUrl + '/api/user/changepassword.php', body, {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((res) => {
               if (res.data.message == "Password has been changed successfully") {
                  alert("Password has been changed successfully");
                  this.setState({ newpassword: "", conformpassword: "", currentpassword: "" });
               }
               else {
                  alert("Current Password Not Matched");
               }
            })
            .catch((error) => {
               this.setState({
                  error: error.message
               });
               alert('Request failure');
            })
      }
      else {
         alert("Password Mismatch");
      }
   }
   getcurrent = (e) => {
      this.setState({ currentpassword: e.target.value });
   }
   getnew = (e) => {
      this.setState({ newpassword: e.target.value });
   }
   getconform = (e) => {
      this.setState({ conformpassword: e.target.value });
   }
   gochangeback() {
      $('#onechangepassword').removeClass("active fades");
      $('#one_12').addClass("active fadesback");
   }
   handleChangeLogout() {
      this.props.history.push("/");
      window.localStorage.clear();
      window.location.reload();
   }
   abortLogout() {
      const { appContext } = this.props;
      const { actions } = appContext;
      this.setState({ logoutPopup : false });
      actions.setLogoutPopupActive(false);
   }
   popopo() {
      const { appContext } = this.props;
      const { state, actions } = appContext;
      if (!state.logoutPopupActive) {
         // $("#one_12 .errorpop").toggle();
         this.setState({ logoutPopup : true });
         actions.setLogoutPopupActive(true);
      }
   }
   gochange() {
      $('#one_12').removeClass("active fades");
      $('#onechangepassword').addClass("active fades");
   }
   setStorage() {
      localStorage.setItem("userpoint", "main");
      localStorage.setItem("backid", "front");
      localStorage.setItem('moveid', "");
   }
   setStorage() {
      localStorage.setItem("backid", "front");
   }
   goback() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }

   async switchProfileAcount() {
      try {
         const accountType = localStorage.getItem("profileType");
         const switchTo = accountType === "consumer" ? "producer" : "consumer";

         const switchResult = await switchAccount(switchTo);

         if (switchResult?.status) {
            localStorage.setItem("profileType", switchTo);
            window.location.href = "/";
         } else {
            this.setState({ switchAccountPopup : true });
         }
      } catch (error) {
         console.error("Error while switching profiles:", error);
         this.setState({ switchAccountPopup : true });
      }
   }
   

   render() {
      return (<>
         {/* <div className="newscreen1 newscreen01"> <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_12">*/}
         <div className={`${this.props.pos} screen1`} id="one_12">

            <div className="top" id="myHeader">
               <section className="header headernoti2 settingHeader">
                  <div className="row">
                     {/* <div className="col-4 header_left pr-0">
                        <ul className="nav nav-tabs">
                           <li defaultStyle="margin-left: 6px;"><a className="linkback" onClick={this.goback.bind(this)} data-toggle="tab"><img src="images/back.png" align="img" /></a></li>
                        </ul>
                     </div> */}
                     <div className="col-4 header_center pl-0 pr-0 text-center">
                        <h6>settings</h6>
                     </div>
                  </div>
               </section>
            </div>
            <div className="setting">
               <div className="tab-content setting_content">
                  <ul>
                     {/* <li><Link to={'/how-it-works'} onClick={this.setStorage.bind(this)} target='_blank' className="link" ><img src="images/setting_ts.png" /> About Timesort </Link></li> */}
                     {localStorage.getItem('token') ? (
                        <li><a href="javascript:void(0)" onClick={this.switchProfileAcount.bind(this)} ><img src="images/Request-to-upload temporal-information.png" />{this.state.accountType == "consumer" ? "Change to uploading account" : "Change to private account"}</a></li>
                     ) : ""}
                     <li><Link to={'/tos'} onClick={this.setStorage.bind(this)} target='_blank' rel="noopener noreferrer"><img src="images/setting_t_n_c.png" />Terms of Service</Link></li>
                     <li><Link to={'/privacy-policy'} onClick={this.setStorage.bind(this)} target='_blank' rel="noopener noreferrer"><img src="images/setting_privacy_policy.png" /> Privacy Policy </Link></li>
                     {localStorage.getItem('token') ? (
                        <li><a className="link" onClick={this.gochange.bind(this)}><img src="images/setting_change password.png" /> Change Password </a></li>
                     ) : ''}
                     {/* <li><a href="mailto:info@timesort.com?subject=Human Resources"><img src="images/setting_hr.png" /> Human Resources </a></li>
                     <li><a href="mailto:info@timesort.com?subject=Invite People to Timesort"><img src="images/setting_invite_people.png" /> Invite People to Timesort </a></li> */}
                     <li><a href="/claim-existing-account"><img src="images/onlyme.png" /> Claim existing account </a></li>
                     <li><a href="/media" target='_blank'><img src="images/setting_press.png" /> Press </a></li>
                     <li><a href="/business" target='_blank'><img src="images/setting_business.png" /> Business </a></li>
                     <li><a href="/assistance" target='_blank'><img src="images/setting_help.png" /> Help </a></li>
                     {/* <li><a href="mailto:info@timesort.com?subject=Request"><img src="images/setting_request.png" /> Request </a></li> */}
                     {localStorage.getItem('token') ? (
                        <li onClick={this.popopo.bind(this)}><a className="link"><img src="images/setting_logout.png" /> Logout </a></li>
                     ) : ''}
                  </ul>
               </div>
            </div>
            {localStorage.getItem('token') ? <Footer {...this.props} /> : <GuestFooter {...this.props} />}
            {/* <div className="op errorpop mainpop">
               <div className="errorbtn error h-100"></div>
               <ul>
                  <li className="lipost">Logout</li>
                  <li><p>{localStorage.getItem('screenname') && localStorage.getItem('screenname') !== null ? "Are you sure you would like to logout, " + localStorage.getItem('screenname').split(" ")[0] + "?" : ""}</p></li>
                  <div className="newpop">
                     <button className="upbtn errorbtn" onClick={this.abortLogout.bind(this)}>CANCEL</button>
                     <button className="upbtn errorbtn" onClick={this.handleChangeLogout.bind(this)}>YES</button>
                  </div>
               </ul>
            </div> */}
            {this.state.logoutPopup && (
               <CustomPopUp
                  isOpen={this.state.logoutPopup}
                  onClose={() => this.abortLogout()}
                  popup={{
                     heading: "Logout",
                     message: `Are you sure you would like to logout, ${localStorage.getItem('screenname').split(" ")[0]}?`,
                     onClick: () => {
                        this.handleChangeLogout();
                     },
                  }}
                  multipleButton={true}
               />
            )}

            {this.state.switchAccountPopup && (
               <CustomPopUp
                  isOpen={this.state.switchAccountPopup}
                  onClose={() => this.setState({ switchAccountPopup: false })}
                  popup={{
                     heading: "Switch Account failure",
                     message: "Request to Switch account failed",
                     onClick: () => {
                        this.setState({ switchAccountPopup: false });
                     },
                  }}
                  multipleButton={false}
               />
            )}
         </div>
         <div className="screen1" id="onechangepassword">
            <div className="top newbg" id="myHeader">
               {/* <!-- --------- header ---------------> */}
               <section className="header userlogin">
                  <div className="row">
                     <div className="col-2 header_left pr-0">
                        <ul className="nav nav-tabs">
                           <li onClick={this.gochangeback.bind(this)}>
                              <a className="linkback" data-toggle="tab" >
                                 <RevisitSvg />
                              </a>
                              <p onClick={this.gochangeback.bind(this)} className="hoverIconInfo" >Revisit</p>
                           </li>
                        </ul>
                     </div>
                     <div className="col-8 header_center pl-0 pr-0 text-center">
                        <h6>change password</h6>
                     </div>
                     <div className="col-2 header_right pl-0">
                     </div>
                  </div>
               </section>
            </div>
            <form className="form-signin login changepassword">
               <div className="form-group mb-0">
                  <input type="password" name="password" onChange={this.getcurrent.bind(this)} className="form-control" placeholder="current password" required="" />
               </div>
               <div className="form-group mb-0">
                  <input type="password" name="password" onChange={this.getnew.bind(this)} className="form-control" placeholder="new password" required="" />
               </div>
               <div className="form-group mb-0">
                  <input type="password" name="password" onChange={this.getconform.bind(this)} className="form-control" placeholder="confirm new password" required="" />
               </div>
               <button className="btn btn-lg btn-block" onClick={this.changepassword.bind(this)} type="button">change password</button>
            </form>
         </div>
         {/* </div> */}
      </>)
   }
}
export default Settings;