import axios from "axios";
import $ from 'jquery';
import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import CustomPopUp from '../../CustomPopup';
import { MyContext } from '../../context';
import { getdateformat } from '../../utils/dateFormatterForTI/dateFormatter';
import { formatDate, getLinkElement } from '../../utils/math';
import { fetchTeXCompiledCaption, modifyHref, modifyWebsiteName } from '../../utils/post/manageCaptions';
import {
   getActiveDatesOfTheMonth, handleSubCalendarData, unsetTimetableData, shiftMonthsBackward,
   shiftMonthsForward, addNextAndPrevDates, updateVisibilityOfSubTimetables, determineActiveDateForCurrentMonth
} from "../../utils/timetableHelper/timetableHelper";
import { determineAndUpdateActiveDatesForTimetables } from "../../utils/timetableHelper/timetableActiveDatesUpdater";
import { ReactComponent as TimelineSvg } from '../../utils/svg/Bidirectionally-traversed-timeline.svg';
import { ReactComponent as CalendarSvg } from '../../utils/svg/Calendar.svg';
import { ReactComponent as DepartSvg } from '../../utils/svg/Depart.svg';
import Footer from '../Footer';
import Timetable from '../Timetable/Timetable';
import { formatUnixTimestamp } from "../../utils/time";
import CustomVideoPlayer from "../customVideoPlayer/customVideoPlayer";
import { handleClickOfProfiles } from "../../utils/profiles/clickHandler";
export const DATE_TIME_LATEST_POST = "YYYY-MM-DDTHH:mm";
export const USERNAME_REGEX = /^[a-zA-Z0-9_-]+s*$/;
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const GRADIENT_COLORS = [
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
   { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
   { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
   { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
   { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];
class Myprofile extends React.Component {
   timeoutId = null;
   constructor(props) {
      super(props);
      let edit_id = "";
      if (localStorage.getItem('moveid') === "") {
         edit_id = "";
      }
      else {
         edit_id = "";
      }
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, static: "", loaddocument: false, hasMore: true, marks: [], maximum: "", minimum: "", monthyear: [], Nextd: "", Previousd: "",
         valuedataprofile: new Date(), checked: "", inverse: true, evntpage: 0, eusername: localStorage.getItem("username"), eventid: "", email: "", password: "",
         escreen: "", e: edit_id, imageUrl: "", currentpassword: "", newpassword: "", conformpassword: "", contat: "of", topcontat: "of",
         evntData: [], profilecheck: "", profiledata: [], editerror: "", errortitle: "", fistname: 0,
         baseUrlNode: process.env.REACT_APP_BASE_URL_NODE, pathname: this.props.location.pathname, savePost: [],
         leftSideArray: [],
         rightSideArray: [],
         currentMonthYear: "",
         monthYearDateMap: {},
         monthYearShortAndFull: {},
         cwebsite: "",
         openPopup: false,
         userDetails: {},
         timetableData: {
            threeMonthsEarlier: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}               
            },
            twoMonthsEarlier: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            previous: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            current: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            next: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            twoMonthsAhead: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            },
            threeMonthsAhead: {
               activeDate: undefined,
               prevDate: undefined,
               nextDate: undefined,
               minDate:undefined,
               maxDate:undefined,
               profileData: [],
               availableEventsOnDates: [],
               appendedTiles: {data: [], appendedMonth: null},
               savePost: [],
               subTimetables: {}
            }
         },
         deletePost: {date: undefined, calendar: "", subCalendar: ""},
         updateTemporalInformation: false,
      };
      this.calendarRef = React.createRef();
   }

   handleMouseOver() {
      $(".katexContentBlock .katex a").attr("target", "_blank");
      $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
   }
   componentDidMount(setActiveDate) {
      document.addEventListener('mouseover', this.handleMouseOver);
      document.addEventListener('click', this.handleClickOfMyProfile);

      $("#one_2 .errorbtn").click(function () {
         $("#one_2 .errorpop").toggle();
      });
      $("#one_2 .upbutton22").click(function () {
         // $("#one_2 .pupup2").toggle();
         $("#one_2 .pupup2").removeClass("zIndex");

      });
      localStorage.setItem("footer", "first");
      //this.mainProfileColor();
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const { sharedData, setActiveTab } = this.context

      setActiveTab("first")

      if (sharedData) {
         if (sharedData.pageWiseData[this.state.pathname]) {
            this.setState(
               { ...sharedData.pageWiseData[this.state.pathname] },
               () => this.setState({ pathname: this.props.location.pathname })
            );
            return;
         } else if (sharedData.authUser) {
            this.setState(
               { ...sharedData.authUser },
               () => this.setState({ pathname: this.props.location.pathname })
            );
         }
      }

      setTimeout(() => {

         const post = this.state.savePost;
         const userData = post.find((data) => data.user == variable);

         let displayedLastActiveDate = false

         if (userData) {
            const outputFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';

            const convertedDate = moment.tz(userData.date, 'DD-MM-YYYY', moment.tz.guess()).format(outputFormat);
            this.setState({
               profiledata: userData.eventDetail, valuedataprofile: new Date(convertedDate), profilecheck: userData.date,
               marks: userData.allEvents, maximum: userData.maxDate, minimum: userData.minDate, Previousd: userData.previousMonthyear, Nextd: userData.nextMonthYear,
               monthyear: userData.otherMonthYear
            });
            displayedLastActiveDate = true
         }

         fetch(this.state.baseUrl + '/api/event/profileEventDates.php?profileId=' + variable, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "TimeZone": moment.tz.guess(),
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response.length > 0) {
                  this.setState({ marks: responseJson.response.sort((a, b) => (moment(a.eventOn, 'DDMMYYYY') > moment(b.eventOn, 'DDMMYYYY')) ? 1 : -1).reverse() }, () => {
                     var newMinimum = this.state.marks[this.state.marks.length - 1].eventOn;
                     var newMaximum = this.state.marks[0].eventOn;
                     this.mapMonthYearToDate().then(() => this.updateMinMaxDateOfTimetables());
                     var min = moment(newMinimum, 'DD/MM/YYYY');
                     var minie = new Date(min);
                     var maxo = moment(newMaximum, 'DD/MM/YYYY');
                     var me = new Date(maxo);
                     this.setState({ minimum: minie, maximum: me }, () => {
                        var array = [];
                        for (let index = 0; index < this.state.marks.length; index++) {
                           var dateads = moment(this.state.marks[index].eventOn, 'DD/MM/YYYY');
                           var elx = new Date(dateads);
                           var finaldate = moment(elx).format('MMYYYY');
                           array.push(finaldate);
                        }
                        this.setState({ monthyear: Array.from(new Set(array.reverse())), core: true }, () => {
                           this.setState({ monthyear: this.state.monthyear.reverse() }, () => {

                              if (displayedLastActiveDate && this.state.profiledata.length !== 1) return

                              var newdate = new Date();
                              var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));
                              if (this.state.e && this.state.e !== "") {
                                 var fdate = moment(this.state.e).format('MMYYYY');
                              }
                              else {
                                 if (idxw && idxw != -1) {
                                    var fdate = moment(newdate).format('MMYYYY');
                                 }
                                 else {
                                    var checker = this.getlatest(responseJson.response, newdate);
                                    if (checker == undefined) {
                                       var metacrox = this.getless(responseJson.response, newdate)
                                    }
                                    else {
                                       var metacrox = checker;
                                    }
                                    var minyy = moment(metacrox, 'DD/MM/YYYY');
                                    var minieyy = new Date(minyy);
                                    var fdate = moment(minieyy).format('MMYYYY');
                                 }
                              }
                              var index_val = this.state.monthyear.indexOf(fdate);
                              if (index_val == 0) {
                                 var previous = this.state.monthyear[index_val + 1];
                                 this.setState({ Nextd: "", Previousd: this.checkdate(previous) });
                              }
                              else {
                                 var previous = this.state.monthyear[index_val + 1];
                                 var next = this.state.monthyear[index_val - 1];
                                 this.setState({ Nextd: this.checkdate(next), Previousd: this.checkdate(previous) });
                              }
                           })
                        });
                     });
                  });

                  if (localStorage.getItem('moveid')) {
                     const dateToBeActive = localStorage.getItem('moveid');

                     this.setState({}, () => {
                        this.showMonthYear(dateToBeActive); // Ensure it's executed after state updates
                        localStorage.removeItem('moveid');
                        return;
                     });
                  }

                  if (setActiveDate) {
                     this.showMonthYear(setActiveDate, false, true);
                     return;
                  };

                  if (displayedLastActiveDate && this.state.profiledata.length !== 1) return

                  var newdate = new Date();
                  var idxw = responseJson.response.findIndex(x => x.eventOn === moment(newdate).format("DD-MM-YYYY"));

                  if (this.state.e && this.state.e !== "") {

                     var datescolor = moment(this.state.e).format('DD-MM-YYYY');
                     this.setState({ valuedataprofile: this.state.e, profilecheck: datescolor });
                  }
                  else {
                     if (idxw && idxw != -1) {
                        var datescolor = this.state.marks[idxw].eventOn;
                        this.setState({ valuedataprofile: newdate, profilecheck: datescolor });

                     }
                     else {
                        var checker = this.getlatest(responseJson.response, newdate);
                        if (checker == undefined) {
                           var metacrox = this.getless(responseJson.response, newdate)
                        }
                        else {
                           var metacrox = checker;
                        }
                        var datescolor = metacrox;
                        var min0 = moment(metacrox, 'DD/MM/YYYY');
                        var miniex = new Date(min0);
                        this.setState({ profilecheck: datescolor, valuedataprofile: miniex });
                     }
                     setTimeout(() => this.showMonthYear(this.state.valuedataprofile), 1);
                  }
                  
                  fetch(`${this.state.baseUrl}/api/event/profileEventDateWise.php?profileId=${variable}&date=${datescolor}`, {
                     method: "GET",
                     headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "TimeZone": moment.tz.guess(),
                        "Authorization": "Bearer " + token,
                     },
                  })
                     .then((response) => response.json())
                     .then((responseJson) => {

                        this.updateSavedPostForLoggedInUser(variable, datescolor, responseJson);

                        this.setState({ profiledata: responseJson.response }, () => {
                           //this.mainProfileColor();
                        });

                        this.updateTemporalInformationStyling(); // update styling of temporal information block
                     })
                     .catch((error) => {
                        this.setState({ profiledata: [] });
                     })

               }
               else {
                  var olddate = new Date();
                  this.setState({ minimum: olddate, maximum: olddate, valuedataprofile: "" });
               }
            })
            .catch((error) => {
            })
         this.myprofile();

      }, 1);
      const websiteValue = localStorage.getItem('website');
      this.setState({ cwebsite: websiteValue })
   }
   updateMinMaxDateOfTimetables() {
      const { monthyear, monthYearDateMap } = this.state;
      const lastIndex = monthyear.length - 1;

      const updateSubCalendarDateRanges = (subtimetables, parent) => {
         if (!subtimetables) return undefined;
      
         const updatedSubtimetables = { ...subtimetables };
         const NEXT_MONTHS = ['threeMonthsAhead', 'twoMonthsAhead', 'next', 'current'];
         const PREV_MONTHS = ['threeMonthsEarlier', 'twoMonthsEarlier', 'previous', 'current'];

         let updatedMaxDate = parent?.maxDate;
         let updatedMinDate = parent?.minDate;

         const adjustMinMaxDateLimits = (months, date, comparisonDate, isNext) => {
            let dateToReturn = date;
            for (let month of months) {
               if (this.state.timetableData[month]?.activeDate) {
                  const timetable = this.state.timetableData[month];
                  const maxMonthYear = moment(comparisonDate).format("MMYYYY");
                  const currentMonthYear = moment(timetable?.activeDate).format("MMYYYY");

                  if (maxMonthYear === currentMonthYear) {
                     const subTimetables = timetable.subTimetables ? Object.values(timetable.subTimetables) : [];
                     if (subTimetables.length) {
                        const isLastSubTimetableVisible = subTimetables[isNext ? subTimetables.length - 1 : 0].isVisible === true;

                        if (!isLastSubTimetableVisible) {
                           dateToReturn = moment(date)
                              .add(isNext ? 1 : -1, 'months')
                              .startOf('month')
                              .toDate();
                        }
                     }
                  }
                  break;
               }
            }
            return dateToReturn;
         }

         updatedMaxDate = adjustMinMaxDateLimits(NEXT_MONTHS, updatedMaxDate, this.state.maximum, true);
         updatedMinDate = adjustMinMaxDateLimits(PREV_MONTHS, updatedMinDate, this.state.minimum, false);

         Object.keys(subtimetables).forEach(subCalendarKey => {
            const events = updatedSubtimetables[subCalendarKey].availableEventsOnDates;
            updatedSubtimetables[subCalendarKey] = {
               ...subtimetables[subCalendarKey],
               minDate: updatedMinDate,
               maxDate: updatedMaxDate
            };
         });

         return updatedSubtimetables;
      }

      this.setState(prevState => ({
         timetableData: {
            threeMonthsEarlier: {
               ...prevState.timetableData.threeMonthsEarlier,
               minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[monthyear[3]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.threeMonthsEarlier.subTimetables,
                  {
                     calendar: 'threeMonthsEarlier',
                     minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[monthyear[3]]?.maxDate
                  }
               )
            },
            twoMonthsEarlier: {
               ...prevState.timetableData.twoMonthsEarlier,
               minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[monthyear[2]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.twoMonthsEarlier.subTimetables,
                  {
                     calendar: 'twoMonthsEarlier',
                     minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[monthyear[2]]?.maxDate
                  }
               )
            },
            previous: {
               ...prevState.timetableData.previous,
               minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[monthyear[1]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.previous.subTimetables,
                  {
                     calendar: 'previous',
                     minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[monthyear[1]]?.maxDate
                  }
               )
            },
            current: {
               ...prevState.timetableData.current,
               minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
               maxDate: monthYearDateMap[monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.current.subTimetables,
                  {
                     calendar: 'current',
                     minDate: monthYearDateMap[monthyear[lastIndex]]?.minDate,
                     maxDate: monthYearDateMap[monthyear[0]]?.maxDate
                  }
               )
            },
            next: {
               ...prevState.timetableData.next,
               minDate: monthYearDateMap[monthyear[lastIndex - 1]]?.minDate,
               maxDate: monthYearDateMap[monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.next.subTimetables,
                  {
                     calendar: 'next',
                     minDate: monthYearDateMap[monthyear[lastIndex - 1]]?.minDate,
                     maxDate: monthYearDateMap[monthyear[0]]?.maxDate
                  }
               )
            },
            twoMonthsAhead: {
               ...prevState.timetableData.twoMonthsAhead,
               minDate: monthYearDateMap[monthyear[lastIndex - 2]]?.minDate,
               maxDate: monthYearDateMap[monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.twoMonthsAhead.subTimetables,
                  {
                     calendar: 'twoMonthsAhead',
                     minDate: monthYearDateMap[monthyear[lastIndex - 2]]?.minDate,
                     maxDate: monthYearDateMap[monthyear[0]]?.maxDate
                  }
               )
            },
            threeMonthsAhead: {
               ...prevState.timetableData.threeMonthsAhead,
               minDate: monthYearDateMap[monthyear[lastIndex - 3]]?.minDate,
               maxDate: monthYearDateMap[monthyear[0]]?.maxDate,
               subTimetables: updateSubCalendarDateRanges(
                  prevState.timetableData.threeMonthsAhead.subTimetables,
                  {
                     calendar: 'threeMonthsAhead',
                     minDate: monthYearDateMap[monthyear[lastIndex - 3]]?.minDate,
                     maxDate: monthYearDateMap[monthyear[0]]?.maxDate
                  }
               )
            }
         }
      }));
   }
   updateSharedDataInContext() {
      const { sharedData, setSharedData } = this.context
      sharedData.pageWiseData[this.state.pathname] = this.state

      setSharedData({
         ...sharedData,
         authUser: this.state,
      });
   }
   handleClickOfMyProfile = (event) => {
      handleClickOfProfiles(event, this.props, this.props.setVisitedPaths);
   };
   componentWillUnmount() {
      this.updateSharedDataInContext();
      if (this.timeoutId)
         clearTimeout(this.timeoutId);
      document.removeEventListener('click', this.handleClickOfMyProfile);
   }
   // setname(){
   //    if(localStorage.getItem('screenname'))
   //    {
   //    var first =localStorage.getItem('screenname').split(" ")[0]
   //    var firstn=first.charAt(0);
   //    if(localStorage.getItem('screenname').split(" ")[1])
   //    {
   //    var second =localStorage.getItem('screenname').split(" ")[1]
   //    var secondn = second.charAt(0); 
   //    this.setState({static:firstn+secondn});
   //    }
   //    else
   //    {
   //    this.setState({static:firstn});
   //    }
   //    }
   //    }
   setStorage() {
      localStorage.setItem("userpoint", "main");
      localStorage.setItem("backid", "front");
      localStorage.setItem('moveid', "");
   }

   checkdate(date) {
      for (let index = 0; index < this.state.marks.length; index++) {
         var dateads = moment(this.state.marks[index].eventOn, 'DD/MM/YYYY');
         var elx = new Date(dateads);
         var finaldate = moment(elx).format('MMYYYY');
         if (date == finaldate) {
            return this.state.marks[index].eventOn;
         }
      }
   }
   getlatest(delta, newdate) {
      for (var index = delta.length - 1; index >= 0; index--) {
         var dateadscc = "";
         var elxc = "";
         dateadscc = moment(delta[index].eventOn, 'DD/MM/YYYY');
         elxc = new Date(dateadscc);
         if (moment(elxc).format("YYYYMMDD") > moment(newdate).format("YYYYMMDD")) {
            return delta[index].eventOn;
         }
      }
   }

   popopo() {
      $("#one_2 .errorpop").toggle();
   }

   myprofile() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const localToken = localStorage.getItem("timeToken");
      //fetch(this.state.baseUrl+'/api/user/getUserDetails.php?user_id='+variable, {
      fetch(this.state.baseUrlNode + '/api/getUserDetails?user_id=' + variable, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            //"Authorization": "Bearer " + token,
            "x-access-token": localToken
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            this.setState({userDetails: responseJson.response});
            localStorage.setItem("followers", responseJson.response.followers);
            localStorage.setItem("following", responseJson.response.following);
         });
   }
   getless(delta, newdate) {
      for (let index = 0; index < delta.length; index++) {
         var dateadscc = "";
         var elxc = "";
         dateadscc = moment(delta[index].eventOn, 'DD/MM/YYYY');
         elxc = new Date(dateadscc);
         if (moment(elxc).format("YYYYMMDD") < moment(newdate).format("YYYYMMDD")) {
            return delta[index].eventOn;
         }
      }
   }
   mainProfileColor() {
      var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
      $(".username, .usernamecl").css("color", "");
      $(".username, .usernamecl").css("color", random_color.textColor);
      $(".username, .usernamecl").css("background-color", "");
      $(".username, .usernamecl").css("background-image", `linear-gradient(${random_color.firstColor}, ${random_color.secondColor})`);
   }
   weekDays = (dayNumber) => {
      if (dayNumber == 0) {
         return "Sunday";
      }
      if (dayNumber == 6) {
         return "Saturday";
      }
      if (dayNumber == 5) {
         return "Friday";
      }
      if (dayNumber == 4) {
         return "Thursday";
      }
      if (dayNumber == 3) {
         return "Wednesday";
      }
      if (dayNumber == 2) {
         return "Tuesday";
      }
      if (dayNumber == 1) {
         return "Monday";
      }
   }

   godate() {
      $('#one_2 #list').removeClass('active');
      $('#one_2 #date').addClass('active');
      $('#one_2').removeClass('text-left profile-pag');
      $('#one_2').addClass('screen1');
      $('#one_2 #onelist').removeClass('active show');
      $('#one_2 #onedate').addClass('active show');
   }
   godocument() {
      $('#one_2 #list').addClass('active');
      $('#one_2 #date').removeClass('active');
      $('#one_2').removeClass('screen1');
      $('#one_2').addClass('text-left profile-pag');
      $('#one_2 #onedate').removeClass('active show');
      $('#one_2 #onelist').addClass('active show');
      this.setState({ fistname: 0 });
      this.fetchEvntList();
   }

   fetchEvntList2() {
      if (this.state.loadthired == false) {
         // let currentHeight =
         //    $("#one_2 #scrollableDivOneFourProfile")[0].scrollHeight -
         //    $("#one_2 #scrollableDivOneFourProfile").height();
         $('#one_2 .loaderclass').removeClass('removescrolling');
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         this.setState({ hasMore: true, loaddocument: true, items: Array.from({ length: 20 }) })
         var time = Math.floor(new Date() / 1000);
         setTimeout(() => {
            $('#one_2 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
            let scrollId = ""
            if (this.state.inverse == false) {
               var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + variable + '&date=' + this.state.evntData[this.state.evntData.length - 1].postDate + '&eventType=0&eventID=' + this.state.evntData[this.state.evntData.length - 1].eventID;
               scrollId = this.state.evntData[this.state.evntData.length - 1].eventID;
            }
            if (this.state.inverse == true) {
               var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + variable + '&date=' + this.state.evntData[0].postDate + '&eventType=1&eventID=' + this.state.evntData[0].eventID;
               scrollId = this.state.evntData[0].eventID;
            }
            fetch(serverpath, {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "TimeZone": moment.tz.guess(),
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {

                  if (responseJson.response.length < 20) {
                     if (this.state.inverse)
                        this.setState({ topcontat: 'on' });
                     else
                        this.setState({ contat: 'on' });
                  }
                  if (responseJson.response.length > 0) {
                     var datas = responseJson.response;
                     if (this.state.inverse == true) {

                        for (var i = datas.length - 1; i >= 0; i--) {
                           this.state.evntData.unshift(datas[i]);
                        }
                     }
                     else {
                        for (var i = datas.length - 1; i >= 0; i--) {
                           this.state.evntData.push(datas[i]);
                        }
                     }
                     if (this.state.inverse == false) {

                        $('#one_2 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                        setTimeout(() => {
                           $('#one_2 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                        }, 100)
                     }
                     if (this.state.inverse == true) {

                        $('#one_2 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                        setTimeout(() => {
                           $('#one_2 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                        }, 100)
                     }
                     // this.setState({ loaddocument: false }, () => {
                     if (this.state.evntData.length > 20) {
                        this.setState({ items: this.state.items.concat(Array.from({ length: 20 })) }, () => {
                        });
                     }
                     // setTimeout(() => {
                     this.setState({ loadthired: false });
                     if (this.state.inverse) {
	                     const scrollSelector = `#hashme${scrollId}`
                     	this.scrollTimelineToElementPosition(scrollSelector)
                     }
                     // }, 100);
                     // });
                  }
                  else {
                     $('#one_2 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                     setTimeout(() => {
                        $('#one_2 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                     }, 100)
                  }
                  this.setState({ loaddocument: false })
               });
         }, 100);
      }
   }

   addTimeoutForDateChange = (callback) => {
      const now = new Date();
      const nextMidnight = new Date(
         now.getFullYear(),
         now.getMonth(),
         now.getDate() + 1,
         0, 0, 0, 0
      );

      const remainingTime = nextMidnight.getTime() - now.getTime();

      if (this.timeoutId) {
         clearTimeout(this.timeoutId);
      }

      this.setState({ updateTemporalInformation: true });

      this.timeoutId = setTimeout(() => {
         try {
            callback();
         } catch (error) {
            console.error('Error updating temporal information:', error);
         }
      }, remainingTime);

      return remainingTime;
   };

   updateTemporalInformation = () => {
      if (this.state.updateTemporalInformation) {
         this.setState({ updateTemporalInformation: false });
         this.addTimeoutForDateChange(this.updateTemporalInformation);
      }
   };

   fetchEvntList() {
      if(this.state.evntData.length >= 20) 
         return

      if (this.state.hasMore == true) {
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         this.setState({ hasMore: true })

         setTimeout(() => {
            var date = Date.now() / 1000;
            var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + variable + '&date=' + date;

            fetch(serverpath, {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "TimeZone": moment.tz.guess(),
                  "Authorization": "Bearer " + token,
               },
            })
               .then((response) => response.json())
               .then((responseJson) => {
                  const timeout = this.addTimeoutForDateChange(this.updateTemporalInformation);
                  const events = responseJson.response;
                  if (events.length < 20)
                     this.setState({ contat: 'on', topcontat: 'on', hasMore: false })

                  if (responseJson.message == "Event not available" && this.state.inverse == false) {
                     $('#one_2 .loaderclass').removeClass('downscroll');
                     $('#one_2 .loaderclass').addClass('stopscroll');
                  }
                  if (events.length) {
                     this.setState({ evntData: events }, () => {
                        const centerEventIndex = Math.floor(events.length / 2)

                        const centerEventHashDivSelector = `#hashme${events[centerEventIndex].eventID}`
                        this.scrollTimelineToElementPosition(centerEventHashDivSelector);
                     })
                  }
               });
         }, 100);
      }
   }


   /**
    * will scroll the Infinite Scroll to *offsetTop* position of the element selected by given jquery selector 
    * 
    * @param {string} selector HTML Element selector 
    */
   scrollTimelineToElementPosition(selector) {
      $('#one_2 #scrollableDivOneFourProfile').scrollTop($(`#one_2 ${selector}`)[0]?.offsetTop - 10);
   }

   // setting()
   // {
   //    $('#one_2').removeClass("active fadesback");
   //    $('#one_2').removeClass("active fades");
   //    $('#one_12').addClass("active fades");
   // }

   goseven3() {
      $('#one_12').removeClass("active fades");
      $('#one_2').addClass("active fadesback");
   }
   // gochange()
   // {
   //    $('#one_12').removeClass("active fades");
   //    $('#onechangepassword').addClass("active fades");
   // }
   gochangeback() {
      $('#onechangepassword').removeClass("active fades");
      $('#one_12').addClass("active fadesback");
   }
   // onClickedit()
   // {
   //    $('#one_2').removeClass("active fadesback");
   //    $('#one_2').removeClass("active fades");
   //    $('#one_17').addClass("active fades");
   //   $('.iconfirst').addClass("colorclass");
   //    $("#second").addClass("displaynone");

   //    if(localStorage.getItem('make_public')==1)
   //    {
   //       this.setState({checked:false});
   //    }
   //    else
   //    {
   //       this.setState({checked:true});
   //    }
   //    this.setState({eusername:localStorage.getItem("username"),
   //    escreen:localStorage.getItem("screenname"),
   //    ebio:localStorage.getItem("bio")
   //    ,eemail:localStorage.getItem("email")
   //    ,eweb:localStorage.getItem("website")
   //   });
   // }
   
   submitdelete() {
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/delete.php?eventID=' + this.state.eventid, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.success == true) {
               const { date } = this.state.deletePost;
               const availableEvents = getActiveDatesOfTheMonth(date, this.state.marks);
               let updatedActiveDate = undefined;

               if (availableEvents?.length) {
                  // updatedActiveDate = determineDateToBeSelectForMonths(date, availableEvents, this.state.marks);
                  updatedActiveDate = determineActiveDateForCurrentMonth(date, availableEvents, this.state.marks);
               }

               this.setState({ deletePost: { date: undefined, calendar: "", subCalendar: "" } }, () => {
                  if (moment(updatedActiveDate).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY") || !updatedActiveDate) {
                     this.componentDidMount();
                  } else {
                     this.componentDidMount(updatedActiveDate);
                  }
               })

               this.myprofile();
               if (this.state.profiledata.length > 1) {
                  var e = this.state.valuedataprofile;
                  // this.onChangedataprofile(e)
               }
               if (this.state.profiledata.length == 1) {
                  this.componentDidMount();
               }
            }
         })
   }
   getbioclass() {
      if (localStorage.getItem('bio') == "null" || localStorage.getItem('bio') == "" && localStorage.getItem('website') == "null" || localStorage.getItem('website') == "") {

         return "inkap blankuser";
      }
      if (localStorage.getItem('bio') === "" && localStorage.getItem('website') === "") {
         return "inkap blankuser";
      }
      if (localStorage.getItem('bio') !== "" && localStorage.getItem('bio') !== "null") {
         if (localStorage.getItem('website') !== "" && localStorage.getItem('website') !== "null") {

            return "inkap";
         }
      } localStorage.setItem("footer", "first");


      if (localStorage.getItem('bio') !== "null" || localStorage.getItem('bio') !== "" && localStorage.getItem('webiste') == "null" || localStorage.getItem('website') == "") {
         return "inkap blankuserone";
      }
      if (localStorage.getItem('bio') == "null" || localStorage.getItem('bio') == "" && localStorage.getItem('webiste') !== "null" || localStorage.getItem('website') !== "") {
         return "inkap blankuserone";
      }
   }

   updateTimetableData = (data, calendarView, subTimetableKey = null) => {
      this.setState((prevState) => {
         if (subTimetableKey && prevState?.timetableData[calendarView]?.subTimetables) {
            return {
               timetableData: {
                  ...prevState.timetableData,
                  [calendarView]: {
                     ...prevState.timetableData[calendarView],
                     subTimetables: {
                        ...prevState?.timetableData[calendarView].subTimetables,
                        [subTimetableKey]: {
                           ...prevState?.timetableData[calendarView].subTimetables[subTimetableKey],
                           ...data,
                        },
                     },
                  },
               },
            };
         } else {
            return {
               timetableData: {
                  ...prevState.timetableData,
                  [calendarView]: {
                     ...prevState.timetableData[calendarView],
                     ...data,
                  },
               },
            };
         }
      });
   }

   updateStatesFromTimetable = (data) => {
      this.setState({
         currentMonthYear: data.activeDate,
         // valueCalendarOther: data.activeDate,
         marks: data.allEvents,
         minimum: data.minDate,
         maximum: data.maxDate,
         monthyear: data.otherMonthYear,
      })
   }

   ensureDisplayedMonthsLimit = (timetableData) => {
      const updatedTimetableData = { ...timetableData };

      const MONTHS = ['current', 'next', 'twoMonthsAhead', 'threeMonthsAhead', 'previous', 'twoMonthsEarlier', 'threeMonthsEarlier'];
      let visibleCount = 0;

      const getNewDateForMonth = (month, prevMonth) => {
         const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';
         let newDate = undefined;

         if (updatedTimetableData[prevMonth]?.nextDate) {
            newDate = updatedTimetableData[prevMonth].nextDate;
         } else if (prevMonth === 'threeMonthsAhead') {
            newDate = updatedTimetableData['current']?.prevDate;
         } else if (isPrevious) {
            newDate = updatedTimetableData[prevMonth]?.prevDate;
         }

         return newDate;
      };

      MONTHS.forEach((month, index) => {
         // if (visibleCount >= 4) return;
         const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';

         if (updatedTimetableData[month]?.activeDate) {
            if (updatedTimetableData[month].subTimetables) {
               const calendars = Object.values(updatedTimetableData[month].subTimetables);
               const iterateCalendars = isPrevious
                  ? calendars.reverse()
                  : calendars;

               let activeSubTimetableIndex = iterateCalendars.findIndex((calendar) => calendar.isActive === true);
               iterateCalendars.forEach((calendar, index) => {
                  if (calendar.isVisible) {
                     visibleCount += 1;
                  } else if (!calendar.isVisible) {
                     if (month !== 'current') {
                        calendar['isVisible'] = true;
                        visibleCount += 1;
                     } else {
                        if (index > activeSubTimetableIndex) {
                           calendar['isVisible'] = true;
                           visibleCount += 1;
                        }
                     }
                  }
               });
            } else {
               visibleCount += 1;
            }
         } else {
            if (month === 'previous') {
               const subTimetables = updatedTimetableData['current'].subTimetables
                  ? Object.values(updatedTimetableData['current'].subTimetables)
                  : [];

               if (subTimetables?.length) {
                  const activeIdx = subTimetables.findIndex((calendar) => calendar.isActive === true);
                  if (activeIdx > 0) {
                     const iterateCalendars = subTimetables.reverse();
                     iterateCalendars.forEach((calendar) => {
                        if (visibleCount < 4) {
                           if (!calendar.isVisible) {
                              calendar['isVisible'] = true;
                              visibleCount += 1;
                           }
                        }
                     })
                  }
               }
            }

            const prevMonth = MONTHS[index - 1];
            const newDate = getNewDateForMonth(month, prevMonth);
            if (!newDate || visibleCount >= 4) return;

            const availableEvents = getActiveDatesOfTheMonth(newDate, this.state.marks);
            // const selectedDate = determineDateToBeSelectForMonths(newDate, availableEvents, this.state.marks);
            const selectedDate = determineActiveDateForCurrentMonth(newDate, availableEvents, this.state.marks);
            const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

            const calendars = subTimetables ? Object.values(subTimetables) : [];
            if (calendars?.length) {
               const iterateCalendars = isPrevious
                  ? calendars.reverse()
                  : calendars;

               iterateCalendars.forEach((calendar) => {
                  if (visibleCount < 4) {
                     calendar['isVisible'] = true;
                     visibleCount += 1;
                  }
               });
            } else {
               visibleCount += 1;
            }

            updatedTimetableData[month].activeDate = selectedDate;
            updatedTimetableData[month].availableEventsOnDates = availableEvents;
            updatedTimetableData[month].subTimetables = subTimetables;
         }
      });

      // If we have more than 4 timetables, start removing the extra ones
      if (visibleCount > 4) {
         const reverseMonths = MONTHS.reverse();

         reverseMonths.forEach((month) => {
            if (visibleCount <= 4) return;

            const isPrevious = month === 'previous' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier';

            if (updatedTimetableData[month]?.activeDate) {
               const calendars = updatedTimetableData[month].subTimetables ? Object.values(updatedTimetableData[month].subTimetables) : [];

               if (calendars?.length) {
                  const iterateCalendars = !isPrevious ? calendars.reverse() : calendars;

                  iterateCalendars.forEach((calendar) => {
                     if (calendar.isVisible && visibleCount > 4) {
                        calendar.isVisible = false;
                        visibleCount -= 1;
                     }
                  });

                  if (calendars.every((calendar) => !calendar.isVisible)) {
                     updatedTimetableData[month].subTimetables = undefined;
                     updatedTimetableData[month].activeDate = undefined;
                     updatedTimetableData[month].availableEventsOnDates = [];
                  }
               }
            }

            if (!updatedTimetableData[month]?.subTimetables && updatedTimetableData[month]?.activeDate) {
               updatedTimetableData[month].activeDate = undefined;
               updatedTimetableData[month].availableEventsOnDates = [];
               visibleCount -= 1;
            }
         });
      }

      return updatedTimetableData;
   };

   shiftActiveCalendar(action) {
      
      //function to update the active month in profiles page header
      const updateActiveMonth = (timetableData) => {
         const newActiveDate = timetableData.current.activeDate;

         const newMonthYear = moment(newActiveDate).format('MMYYYY');
         const existingMonthYear = moment(this.state.currentMonthYear).format('MMYYYY');

         const shouldNotUpdateActiveDates = newMonthYear !== existingMonthYear;

         if (shouldNotUpdateActiveDates) {
            this.showMonthYear(newActiveDate, shouldNotUpdateActiveDates);
         } else {
            this.setState({ currentMonthYear: newActiveDate });
         }
      }

      // function to shift to next calendar
      const shiftToNextCalendar = () => {
         const { current, next } = this.state.timetableData;

         const subTimetables = current.subTimetables
            ? Object.entries(current.subTimetables)
            : [];
         const activeIndex = subTimetables.length
            ? subTimetables.findIndex(([_, timetable]) => timetable.isActive === true)
            : -1;

         const hasSubtimetable =
            subTimetables.length && activeIndex !== subTimetables.length - 1;

         let updatedTimetableData = { ...this.state.timetableData };

         if (hasSubtimetable) {
            updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(
               current.subTimetables,
               activeIndex + 2,
               activeIndex + 1
            );
         } else {
            updatedTimetableData.current = unsetTimetableData(updatedTimetableData.current);

            updatedTimetableData = shiftMonthsForward(updatedTimetableData);
            const currentSubTimetables = updatedTimetableData.current.subTimetables;

            if (currentSubTimetables) {
               updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(currentSubTimetables, 1);
            }
         }
         updatedTimetableData = this.ensureDisplayedMonthsLimit(updatedTimetableData);
         updatedTimetableData = addNextAndPrevDates(updatedTimetableData, this.state.monthyear, this.state.monthYearDateMap);
         updatedTimetableData = determineAndUpdateActiveDatesForTimetables(updatedTimetableData, this.state.marks);

         this.setState({ timetableData: updatedTimetableData }, () => {
            updateActiveMonth(updatedTimetableData);
         });
      };

      // function to shift to previous calendar
      const shiftToPreviousCalendar = () => {
         const { current, next } = this.state.timetableData;

         const subTimetables = current.subTimetables
            ? Object.entries(current.subTimetables)
            : [];
         const activeIndex = subTimetables.length
            ? subTimetables.findIndex(([_, timetable]) => timetable.isActive === true)
            : -1;

         const hasSubtimetable =
            subTimetables.length && activeIndex !== 0;

         let updatedTimetableData = { ...this.state.timetableData };

         if (hasSubtimetable) {            
            updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(
               current.subTimetables,
               activeIndex
            );
         } else {
            updatedTimetableData = shiftMonthsBackward(updatedTimetableData);

            updatedTimetableData.current = unsetTimetableData(updatedTimetableData.current);
            const newDate = updatedTimetableData.next.prevDate;

            const availableEvents = getActiveDatesOfTheMonth(newDate, this.state.marks);
            const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

            if (subTimetables) {
               updatedTimetableData.current.subTimetables = updateVisibilityOfSubTimetables(subTimetables, Object.values(subTimetables)?.length);
            }

            updatedTimetableData.current.activeDate = newDate;
            updatedTimetableData.current.availableEventsOnDates = availableEvents;
         }

         updatedTimetableData = this.ensureDisplayedMonthsLimit(updatedTimetableData);
         updatedTimetableData = addNextAndPrevDates(updatedTimetableData, this.state.monthyear, this.state.monthYearDateMap);
         updatedTimetableData = determineAndUpdateActiveDatesForTimetables(updatedTimetableData, this.state.marks);

         this.setState({ timetableData: updatedTimetableData }, () => {
            updateActiveMonth(updatedTimetableData);
         });
      }

      // call the function based on the action
      if (action === "next") {
         shiftToNextCalendar();
      } else {
         shiftToPreviousCalendar();
      }

      this.updateMinMaxDateOfTimetables();
   }

   updateActiveDatesOfTimetables(newActiveDate, action, calendar) {

      // call shiftActiveCalendar function to shift calendar based on the action
      this.shiftActiveCalendar(action);
   }

   getActiveDatesOfNextPrevMonths(showDate, monthDateMapping, makeDateDefaultActive = false) {
      const activeDates = {
         threeMonthsAhead: undefined,
         twoMonthsAhead: undefined,
         next: undefined,
         current: undefined,
         prev: undefined,
         twoMonthsEarlier: undefined,
         threeMonthsEarlier: undefined
      };

      const currentMonthIndex = monthDateMapping.findIndex(el => el.isActiveMonth);
      let visibleCount = 1;

      const processDateAtIndex = (index, month) => {
         const date = monthDateMapping[currentMonthIndex + index]?.date;

         if (!date || visibleCount > 4) return undefined;

         const availableEvents = getActiveDatesOfTheMonth(date, this.state.marks);
         // const newDate = makeDateDefaultActive ? date : determineDateToBeSelectForMonths(date, availableEvents, this.state.marks);
         const newDate = makeDateDefaultActive ? date : determineActiveDateForCurrentMonth(date, availableEvents, this.state.marks);
         const subTimetables = availableEvents?.length >= 5 ? handleSubCalendarData(availableEvents) : undefined;

         if (subTimetables && Object.keys(subTimetables)?.length) {
            const calendars = Object.values(subTimetables);

            const iterateCalendars = (month === 'prev' || month === 'twoMonthsEarlier' || month === 'threeMonthsEarlier')
               ? calendars.reverse() // Start from the last calendar for previous months
               : calendars; // Start from the first calendar for other months

            if (month === 'current') {
               const activeIndex = calendars.findIndex(el => {
                  const eventDates = el.availableEventsOnDates.map(event => moment(event.eventOn, "DD-MM-YYYY"));

                  if (eventDates.length === 0) return false;

                  const minDate = moment.min(eventDates);
                  const maxDate = moment.max(eventDates);

                  return moment(newDate).isBetween(minDate, maxDate, 'day', '[]');
               });

               const selectedIndex = activeIndex >= 0 ? activeIndex : 0;

               calendars[selectedIndex].isActive = true;

               iterateCalendars.slice(selectedIndex).forEach(calendar => {
                  if (visibleCount > 4) return;
                  calendar['isVisible'] = true;
                  visibleCount += 1;
               });
            } else {
               iterateCalendars.forEach((calendar) => {
                  if (visibleCount > 4) return;
                  calendar['isVisible'] = true;
                  visibleCount += 1;
               });
            }
         } else {
            visibleCount += 1;
         }

         if (date) {
            activeDates[month] = {
               activeDate: newDate,
               availableEventsOnDates: availableEvents,
               subTimetables: subTimetables
            }
         }
         return newDate;
      };

      let currentDate = processDateAtIndex(0, 'current');
      let nextMonthDate = processDateAtIndex(1, 'next');
      let twoMonthsAheadDate = processDateAtIndex(2, 'twoMonthsAhead');
      let threeMonthsAheadDate = processDateAtIndex(3, 'threeMonthsAhead');
      let prevMonthDate = null, twoMonthsEarlierDate = null, threeMonthsEarlierDate = null;

      let pastMonthsNeeded = 0;
      if (!threeMonthsAheadDate) pastMonthsNeeded = 1;
      if (!twoMonthsAheadDate) pastMonthsNeeded = 2;
      if (!nextMonthDate) pastMonthsNeeded = 3;


      if (pastMonthsNeeded > 0) {
         prevMonthDate = processDateAtIndex(-1, 'prev');
         if (pastMonthsNeeded > 1) {
            twoMonthsEarlierDate = processDateAtIndex(-2, 'twoMonthsEarlier');
            if (pastMonthsNeeded > 2) {
               threeMonthsEarlierDate = processDateAtIndex(-3, 'threeMonthsEarlier');
            }
         }
      }

      const updatedActiveDates = addNextAndPrevDates(activeDates, this.state.monthyear, this.state.monthYearDateMap);
      return updatedActiveDates;
   }

   // onChangedataprofile = (e) => {
   //    const token = localStorage.getItem('token');
   //    const variable = localStorage.getItem('userid');
   //    var tiger = moment(e).format('DD-MM-YYYY');
   //    if (this.state.marks.find(x => x.eventOn === tiger)) {
   //       this.setState({ valuedataprofile: e });
   //       fetch(this.state.baseUrl + '/api/event/profileEventDateWise.php?profileId=' + variable + '&date=' + tiger, {
   //          method: "GET",
   //          headers: {
   //             Accept: "application/json",
   //             "Content-Type": "application/json",
   //             "TimeZone": moment.tz.guess(),
   //             "Authorization": "Bearer " + token,
   //          },
   //       })
   //          .then((response) => response.json())
   //          .then((responseJson) => {

   //             this.getNextForDate(e);
   //             this.updateSavedPostForLoggedInUser(variable, tiger, responseJson);

   //             this.setState({ profiledata: [] }, () => {
   //                this.setState({ profiledata: responseJson.response }, () => {
   //                   //this.mainProfileColor();
   //                });
   //             })
               
   //             this.updateTemporalInformationStyling(); // update styling of temporal information block
   //          })
   //          .catch((error) => {
   //          })
   //    }
   // }

   updateSavedPostForLoggedInUser(variable, activeDate, responseJson) {
      const calendarActiveDateInfo = {
         user: variable,
         allEvents: this.state.marks,
         minDate: this.state.minimum,
         maxDate: this.state.maximum,
         previousMonthyear: this.state.Previousd,
         nextMonthYear: this.state.Nextd,
         otherMonthYear: this.state.monthyear
      };

      this.addPostsOfUserToSavePost(activeDate, calendarActiveDateInfo, responseJson);
   }

   addPostsOfUserToSavePost(postDate, calendarActiveDateInfo, postDetail) {
      const post = this.state.savePost;
      const opendUser = calendarActiveDateInfo.user;
      const isUserPresent = post.findIndex((data) => data.user == opendUser);

      if (isUserPresent !== -1) {
         const updatedSavePost = post.map((data) => {
            if (data.user === opendUser) {
               return {
                  ...data,
                  date: postDate,
                  eventDetail: postDetail.response,
                  nextMonthYear: calendarActiveDateInfo.nextMonthYear,
                  previousMonthyear: calendarActiveDateInfo.previousMonthyear,
               };
            } else {
               return data;
            }
         });
         this.setState({ savePost: updatedSavePost });
      }
      else {
         this.setState((prevState) => ({
            savePost: [
               ...prevState.savePost,
               {
                  date: postDate,
                  user: calendarActiveDateInfo.user,
                  eventDetail: postDetail.response,
                  allEvents: calendarActiveDateInfo.allEvents,
                  minDate: calendarActiveDateInfo.minDate,
                  maxDate: calendarActiveDateInfo.maxDate,
                  previousMonthyear: calendarActiveDateInfo.previousMonthyear,
                  nextMonthYear: calendarActiveDateInfo.nextMonthYear,
                  otherMonthYear: calendarActiveDateInfo.otherMonthYear
               },
            ],
         }));
      }
   }
   onchangelable() {
      if (this.state.profilecheck) {
         $('.newscreen1 #one_2 .react-calendar__navigation__prev-button').unbind().click(function () {
            var mydate = this.state.Previousd;
            var min = moment(mydate, 'DD/MM/YYYY');
            var minie = new Date(min);
            this.showMonthYear(minie);
            this.setState({ valuedataprofile: minie }, () => {
               this.getNextPrevious(minie);
            });
         }.bind(this));
         $('.newscreen1 #one_2 .react-calendar__navigation__next-button').unbind().click(function () {
            var mydatet = this.state.Nextd;
            var minx = moment(mydatet, 'DD/MM/YYYY');
            var minief = new Date(minx);
            this.showMonthYear(minief);
            this.setState({ valuedataprofile: minief }, () => {
               this.getNextPrevious(minief);
            });
         }.bind(this));
      }
   }
   likepost(e) {
      $("#" + e.currentTarget.id).toggleClass("thumbs-down");
      var hascc = $('div').hasClass('thumbs-down');
      if (hascc == true) {
         setTimeout(function () {
            $('.thumbs-down').removeClass('thumbs-down');
         }, 1500);
      }
      var evtid = e.currentTarget.id.replace('crox', '');
      const body = {
         user_id: localStorage.getItem('userid'),
         eventID: evtid,
      }
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/addOrRemoveLike.php', body, {
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            console.log('liked');
         });
   }
   changeCurrentTimeOld(seconds, players) {
      let playerd = this[`ref${players}`].getState().player.currentTime;
      this[`ref${players}`].seek(playerd + seconds);
   }
   editdeletepost(e, post, calendar, subKey) {
      const [date, abrivation] = formatUnixTimestamp(post?.postDate, post?.timezone);
      const activeDate = moment(date).toDate();
      
      // $("#one_2 .pupup2").toggle();
      $("#one_2 .pupup2").addClass("zIndex");

      this.setState({ eventid: "" });
      this.setState({ eventid: e.currentTarget.id, deletePost: {date: activeDate, calendar: calendar, subCalendar: subKey } })
   }
   onscrolldocument() {
      var pxcelate = $('#one_2 #scrollableDivOneFourProfile')[0].scrollHeight;
      var px = $("#one_2 #scrollableDivOneFourProfile").scrollTop();
      if (px < 50) {
         if (this.state.topcontat == "of") {
            this.setState({ inverse: true, loadthired: false }, () => {
               if (px == 0) {
                  this.timeout = setTimeout(() => {
                     this.fetchEvntList2();
                  }, 100);
                  setTimeout(() => {
                     if (this.timeout) {
                        clearTimeout(this.timeout)
                        this.timeout = null
                     }
                  }, 100);
               }
            });
            // $('#one_2 .loaderclass').removeClass('bottomscrolls closeCd');
            // $('#one_2 .loaderclass').addClass('topscrolls');
            $('#one_2 .contantf').addClass('closeCd');
         }
         if (this.state.topcontat == "on") {
            $('#one_2 .contantf').removeClass('closeCd');
            // $('#one_2 .loaderclass').addClass('closeCd');
         }
         else {
            $('#one_2 .contantf').addClass('closeCd');
         }
      }
      else {
         if (this.state.contat == "of") {
            this.setState({ inverse: false, loadthired: false }, () => {

               if (Math.round(px + $("#one_2 #scrollableDivOneFourProfile").height()) == pxcelate) {
                  this.timeout = setTimeout(() => {
                     this.fetchEvntList2();
                  }, 100);
                  setTimeout(() => {
                     if (this.timeout) {
                        clearTimeout(this.timeout)
                        this.timeout = null
                     }
                  }, 100);
               }
            });
            // $('#one_2 .loaderclass').removeClass('topscrolls closeCd');
            // $('#one_2 .loaderclass').addClass('bottomscrolls');
         }
         if (this.state.contat == "on" && Math.round(px + $("#one_2 #scrollableDivOneFourProfile").height()) == pxcelate) {
            $('#one_2 .contantf').removeClass('closeCd');
            // $('#one_2 .loaderclass').addClass('closeCd');
         }
         else {
            $('#one_2 .contantf').addClass('closeCd');
         }
      }
   }



   showMonthYear(showDate, shouldNotUpdateActiveDates, makeDateDefaultActive) {
      const monthsShort = {
         '01': "Jan",
         '02': 'Feb',
         '03': 'Mar',
         '04': 'Apr',
         '05': 'May',
         '06': 'Jun',
         '07': 'Jul',
         '08': 'Aug',
         '09': 'Sep',
         '10': 'Oct',
         '11': 'Nov',
         '12': 'Dec'
      };
      this.setState({ currentMonthYear: showDate }, () => {
         const dateActive = this.state.currentMonthYear
         const dateIndex = moment(dateActive).format("MMYYYY")
         const sortedArray = this.getSurroundingMonths(dateIndex);
         const result = sortedArray.map(({ month, year, originalIndex }, index) => {
            return {
               date: `${month < 10 ? '0' : ''}${month}${year}`,
               originalIndex,
               display: index < 5
            }
         })
            .sort((a, b) => a.originalIndex - b.originalIndex);
         const activeIndex = result.indexOf(dateIndex);
         const mappedArray = result.map(dateObj => {
            let { date, display } = dateObj
            const month = date.substr(0, 2);
            const year = date.substr(4, 2);
            const monthName = monthsShort[month];
            const currentDate = new Date();
            const currentYear = moment(currentDate).format('YYYY');
            // const MY = `${monthName}${date.substr(2, 4) !== currentYear ? ", '" + year : ''}`
            const MY = `${monthName}`

            return { short: MY, date: this.state.monthYearDateMap[date].maxDate, isActiveMonth: date === dateIndex, display }
         });

         this.setState({
            leftSideArray: mappedArray.filter(map => map.display)
         })

         if (shouldNotUpdateActiveDates)
            return

         const {threeMonthsEarlier, twoMonthsEarlier, prev, current, next, twoMonthsAhead, threeMonthsAhead} = this.getActiveDatesOfNextPrevMonths(showDate, mappedArray, makeDateDefaultActive);         
         this.setState(prevState => ({
            timetableData: {
               threeMonthsEarlier: {
                  ...prevState.timetableData.threeMonthsEarlier,
                  activeDate: threeMonthsEarlier?.activeDate,
                  availableEventsOnDates: threeMonthsEarlier?.availableEventsOnDates,
                  subTimetables: threeMonthsEarlier?.subTimetables,
                  nextDate: threeMonthsEarlier?.nextDate || undefined,
                  prevDate: threeMonthsEarlier?.prevDate || undefined
               },
               twoMonthsEarlier: {
                  ...prevState.timetableData.twoMonthsEarlier,
                  activeDate: twoMonthsEarlier?.activeDate,
                  availableEventsOnDates: twoMonthsEarlier?.availableEventsOnDates,
                  subTimetables: twoMonthsEarlier?.subTimetables,
                  nextDate: twoMonthsEarlier?.nextDate || undefined,
                  prevDate: twoMonthsEarlier?.prevDate || undefined
               },
               previous: {
                  ...prevState.timetableData.previous,
                  activeDate: prev?.activeDate,
                  availableEventsOnDates: prev?.availableEventsOnDates,
                  subTimetables: prev?.subTimetables,
                  nextDate: prev?.nextDate || undefined,
                  prevDate: prev?.prevDate || undefined
               },
               current: {
                  ...prevState.timetableData.current,
                  activeDate: current?.activeDate,
                  availableEventsOnDates: current?.availableEventsOnDates,
                  subTimetables: current?.subTimetables,
                  nextDate: current?.nextDate || undefined,
                  prevDate: current?.prevDate || undefined
               },
               next: {
                  ...prevState.timetableData.next,
                  activeDate: next?.activeDate,
                  availableEventsOnDates: next?.availableEventsOnDates,
                  subTimetables: next?.subTimetables,
                  nextDate: next?.nextDate || undefined,
                  prevDate: next?.prevDate || undefined
               },
               twoMonthsAhead: {
                  ...prevState.timetableData.twoMonthsAhead,
                  activeDate: twoMonthsAhead?.activeDate,
                  availableEventsOnDates: twoMonthsAhead?.availableEventsOnDates,
                  subTimetables: twoMonthsAhead?.subTimetables,
                  nextDate: twoMonthsAhead?.nextDate || undefined,
                  prevDate: twoMonthsAhead?.prevDate || undefined
               },
               threeMonthsAhead: {
                  ...prevState.timetableData.threeMonthsAhead,
                  activeDate: threeMonthsAhead?.activeDate,
                  availableEventsOnDates: threeMonthsAhead?.availableEventsOnDates,
                  subTimetables: threeMonthsAhead?.subTimetables,
                  nextDate: threeMonthsAhead?.nextDate || undefined,
                  prevDate: threeMonthsAhead?.prevDate || undefined
               }
            }
         }), () => {
            const updatedTimetableData = determineAndUpdateActiveDatesForTimetables(this.state.timetableData, this.state.marks);
            this.setState({ timetableData: updatedTimetableData });
            this.updateMinMaxDateOfTimetables();
         })
      });
   }
   getClosestValues = (activeIndex, array) => {
      const parseMonthYear = (value) => {
         const year = parseInt(value.slice(2, 6), 10);
         const month = parseInt(value.slice(0, 2), 10);
         return { year, month };
      };
      const activeDate = parseMonthYear(activeIndex);
      const parsedArray = array.map((value, index) => ({ ...parseMonthYear(value), originalIndex: index }));
      const sortedArray = parsedArray.sort((a, b) => {
         const diffA = Math.abs((a.year - activeDate.year) * 12 + (a.month - activeDate.month));
         const diffB = Math.abs((b.year - activeDate.year) * 12 + (b.month - activeDate.month));
         return diffA - diffB;
      });
      return sortedArray;
   };
   getSurroundingMonths = (activeMonth) => {
      let months = [...this.state.monthyear];
      months.reverse();
      if (months?.length && activeMonth) {
         const activeIndex = months[months.indexOf(activeMonth)];
         const surroundingMonths = this.getClosestValues(activeIndex, months);
         return surroundingMonths;
      }
   }
   mapMonthYearToDate = async () => {
      let lastMY = ""
      let lastMYDate = ""
      let monthYearDateMap = {}
      this.state.marks.forEach((dateObj) => {
         var min = moment(dateObj.eventOn, "DD/MM/YYYY");
         var minie = new Date(min);
         const currentMY = moment(minie).format("MMYYYY")
         if (currentMY !== lastMY) {
            monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
            monthYearDateMap[currentMY] = { ...monthYearDateMap[lastMY], maxDate: minie }
         }
         lastMY = currentMY
         lastMYDate = minie
      })
      monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
      this.setState({ monthYearDateMap: { ...monthYearDateMap } })
   }
   getMonthYear(maxDate) {
      var mydate = maxDate;
      var min = moment(mydate, "DD/MM/YYYY");
      var minie = new Date(min);
      this.showMonthYear(minie, false, true);
      this.setState({ valuedataprofile: minie }, () => {
         this.getNextPrevious(minie);
      });
   }
   getDateFullName(date) {
      if (date) {
         const month = moment(date).format('MMMM');
         const year = moment(date).format('YYYY');
         return month + ' ' + year;
      }
   }
   changeCurrentTimeNew(seconds, players) {
      let playerd = this[`ref${players}`].getState().player.currentTime;
      this[`ref${players}`].seek(playerd + seconds);
   }

   userpoint() {

      if (localStorage.getItem('bio') === "null" && localStorage.getItem('website') === "null") {
         return "userpoint";
      }
      if (localStorage.getItem('bio') === "" && localStorage.getItem('website') === "") {
         return "userpoint";
      }
      if (localStorage.getItem('bio') === "" && localStorage.getItem('website') === "null") {
         return "userpoint";
      }
      if (localStorage.getItem('website') === "" && localStorage.getItem('bio') === "null") {
         return "userpoint";
      }
      if (localStorage.getItem('bio') != "" && localStorage.getItem('website') === "" | localStorage.getItem('website') === "null") {
         return "userpoint blankuserone";
      }
      if (localStorage.getItem('website') != "" && localStorage.getItem('bio') === "" | localStorage.getItem('bio') === "null") {
         return "userpoint blankuserone";
      }
      if (localStorage.getItem('bio') !== "null" && localStorage.getItem('bio') !== "null") {
         if (localStorage.getItem('website') !== "" && localStorage.getItem('website') !== "") {

            return "userpoint";
         }
      }
   }
   collectMentions(value) {
      var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      var hash = value.match(/(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
      if (more || hash) {
         if (more !== null && more.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < more.length; index++) {
                  $("." + more[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/' + more[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
         if (hash !== null && hash.length > 0) {
            $(document).ready(function () {
               for (let index = 0; index < hash.length; index++) {
                  $("." + hash[index].substring(1)).unbind().click(function () {
                     this.props.history.push({
                        pathname: '/tag/' + hash[index].substring(1),
                     })
                     this.setStorage();
                  }.bind(this));
               }
            }.bind(this));
         }
      }
   }
   // collectBio(value)
   // {
   //   var more = value.match(/(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig);
   //   if(more)
   //   {
   //   if(more.length>0)
   //   {
   //      $(document).ready(function() {
   //      setTimeout(() => {
   //      for (let index = 0; index < more.length; index++) {
   //         $("#"+more[index].substring(1)).unbind().click(function() {
   //         this.props.history.push('/'+more[index].substring(1));
   //            localStorage.setItem("backid","front");
   //         }.bind(this));
   //      }
   //    },3000)
   // }.bind(this))
   //     }
   //   }
   // }
   getNextPrevious(dates) {
      var e = dates;
      // this.onChangedataprofile(e);
      const newDate = moment(dates).format('MMYYYY');
      var index_val = this.state.monthyear.indexOf(newDate);
      if (index_val == 0) {
         var previous = this.state.monthyear[index_val + 1];
         this.setState({ Nextd: "", Previousd: this.checkdate(previous) });
      }
      else {
         var previous = this.state.monthyear[index_val + 1];
         var next = this.state.monthyear[index_val - 1];
         this.setState({ Nextd: this.checkdate(next), Previousd: this.checkdate(previous) });
      }
   }
   getNextForDate(dates) {
      this.showMonthYear(dates)
      const newDate = moment(dates).format('MMYYYY');
      var index_val = this.state.monthyear.indexOf(newDate);
      if (index_val == 0) {
         var previous = this.state.monthyear[index_val + 1];
         this.setState({ Nextd: "", Previousd: this.checkdate(previous) });
      }
      else {
         var previous = this.state.monthyear[index_val + 1];
         var next = this.state.monthyear[index_val - 1];
         this.setState({ Nextd: this.checkdate(next), Previousd: this.checkdate(previous) });
      }
   }

   // --------editprofile-------


   fetchafterfilter(jstatus) {
      this.collectMentions(jstatus);
      var regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
      const replaced2 = jstatus.replace(regex, value => {
         const link = getLinkElement(value)
         return `<a href=${"https://" + value} target='_blank'>${link}</a>`
      });
      //  var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
      var mention_preg = /(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)/ig;
      var replaced7 = replaced2.replace(mention_preg, value => `<a class=${value.substring(1)}>${value}</a>`);
      var regex2 = /!+([a-zA-Z0-9_]+)/ig;
      var replaced3 = replaced7.replace(regex2, value => `<span>${value}</span>`);
      var replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return replaced;
   }
   // handleChangeLogout()
   //   {
   //     window.localStorage.clear();
   //     this.props.history.push("/");
   //   }
   // getcurrent=(e)=>{
   // 	this.setState({currentpassword:e.target.value});
   // }
   // getnew=(e)=>{
   // 	this.setState({newpassword:e.target.value});
   // }
   // getconform=(e)=>{
   // 	this.setState({conformpassword:e.target.value});
   // }
   // changepassword(){
   // 	if(this.state.newpassword==this.state.conformpassword)
   // 	{
   //       const body={current_password:this.state.currentpassword,
   //       new_password:this.state.newpassword,
   //       confirm_password:this.state.conformpassword}
   //       const token = localStorage.getItem('token');
   //       axios.post(this.state.baseUrl+'/api/user/changepassword.php',body, {
   //          headers: {
   //         Accept: "application/json",
   //        "Content-Type": "application/json",
   //         "Authorization": "Bearer " + token,
   //        },
   //         })
   //         .then((res) => {
   //    		if(res.data.message=="Password has been changed successfully")
   //    		{
   //    			alert("Password has been changed successfully");
   //    			this.setState({newpassword:"",conformpassword:"",currentpassword:""});
   //    		}
   //    		else
   //    		{
   //    			alert("Current Password Not Matched");
   //    		}
   //  		})
   //     .catch((error) => {
   //       this.setState({
   //         error: error.message
   //       });
   //       alert('Request failure');
   //     }) 
   // }
   // else
   // {
   // 	alert("Password Mismatch");
   // }
   // }
   removeuserpop() {
      $(".pupup4").toggle();
      this.setState({ cscreenname: "", c_userid: "", c_event: "", follower: 0 });
   }
   thiredSeeMore() {
      $(".pupup4").toggle();
      $('#promo' + this.state.c_event).addClass('closeBd');
      setTimeout(function () {
         $('#promo' + this.state.c_event).removeClass('closeBd');
      }.bind(this), 3000);
   }
   blockuserpop() {
      $(".pupup4").toggle();
      this.setState({ errortitle: "Block " + this.state.cscreenname.split(" ")[0], editerror: "Are you sure would like to block " + this.state.cscreenname + ", " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $("#one_2 .errorpop").toggle();
      });
   }
   removefollowpop() {
      $(".pupup4").toggle();
      this.setState({ errortitle: "Remove Follower", editerror: "Are you sure would like to remove " + this.state.cscreenname + " as a follower, " + localStorage.getItem("screenname").split(" ")[0] + "?" }, () => {
         $("#one_2 .errorpop").toggle();
      });
   }
   thiredLessMore() {
      $(".pupup4").toggle();
      $('#promi' + this.state.c_event).addClass('closeBd');
      setTimeout(function () {
         $('#promi' + this.state.c_event).removeClass('closeBd');
      }.bind(this), 3000);
   }
   /////login//////////////
   onClick() {
      const body = {
         email: this.state.email,
         password: this.state.password,
         deviceType: "android",
         deviceToken: "cHoLreb1SGacDSQdDS-yFP:APA91bHDXlU8BgbbbuHyMIYjMhRC2OQK3KIs1BbuUSqoIp9IZsrn2xX7m2l06MEdVUbgkqet962BA61SD2vqpv47-B1jtxNxAGgYyo7BwqE_uS-jrQXcsYqMi1hNRfnBi99AYXuXRKCA",
         deviceId: "62406169083a90ad67"
      };
      axios.post(this.state.baseUrl + '/api/user/login.php',
         body
      )
         .then((res) => {
            localStorage.setItem("email", res.data.response.email);
            localStorage.setItem("screenname", res.data.response.screenName);
            localStorage.setItem("totalpost", res.data.response.totalPost);
            localStorage.setItem("username", res.data.response.username);
            localStorage.setItem("followers", res.data.response.followers);
            localStorage.setItem("request", res.data.response.request);
            localStorage.setItem("bio", res.data.response.bio);
            localStorage.setItem("following", res.data.response.following);
            localStorage.setItem("profileCover", res.data.response.profileCover);
            localStorage.setItem("profileImage", res.data.response.profileImage);
            localStorage.setItem("userid", res.data.response.user_id);
            localStorage.setItem("token", res.data.response.token);
            localStorage.setItem('make_public', res.data.response.make_public);
            localStorage.setItem("website", res.data.response.website);
            window.location.reload();
            var first = localStorage.getItem('screenname').split(" ")[0]
            var firstn = first.charAt(0);
            if (localStorage.getItem('screenname').split(" ")[1]) {
               var second = localStorage.getItem('screenname').split(" ")[1]
               var secondn = second.charAt(0);
               localStorage.setItem("staticname", firstn + secondn);
            }
            else {
               localStorage.setItem("staticname", firstn);
            }
         })
         .catch((error) => {
            alert('Invalid Login');
         })
   }
   handleChangeEmail(e) {
      this.setState({ email: e.target.value });
   }
   handleChangePassword(e) {
      this.setState({ password: e.target.value });
   }
   render() {

      return (
         <>
            {
               // <div className="newscreen1 newscreen01">
               localStorage.getItem('userid') && localStorage.getItem('userid') !== null ?
                  //  <div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"} id="one_2">  */}
                  <div className={`${this.props.pos} screen1`} id="one_2">
                     <div className="top newbg" id="myHeader">
                        {/* <!-- --------- header ---------------> */}
                        <section className="header">
                           <div className="row">
                              <div className=" header_left pr-0">
                                 <ul className="nav nav-tabs noteee">
                                    <li>
                                       <a className="active" onClick={this.godate.bind(this)} id="date" data-toggle="tab" >
                                          <CalendarSvg />
                                       </a>
                                       <p onClick={this.godate.bind(this)} id="date" className='hoverIconInfo'>Timetables</p>
                                    </li>
                                    <li>
                                       <a data-toggle="tab" className="" onClick={this.godocument.bind(this)} id="list" >
                                          <TimelineSvg />                                       </a>
                                       <p onClick={this.godocument.bind(this)} id="list" className='hoverIconInfo'>Bidirectionally-traversed timeline</p>
                                    </li>
                                 </ul>
                              </div>
                              <div className=" monthsBlock">
                                 <ul>
                                    {
                                       this.state.leftSideArray &&
                                       this.state.leftSideArray.map((item) =>
                                          <li>
                                             <p className={`dateInfo ${item.isActiveMonth ? "active" : ""}`} onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                             <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                          </li>
                                       )
                                    }
                                    {
                                       this.state.rightSideArray &&
                                       this.state.rightSideArray.map((item) =>
                                          <li>
                                             <p onClick={() => this.getMonthYear(item.date)}>{item.short}</p>
                                             <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                          </li>
                                       )
                                    }
                                 </ul>
                              </div>

                              {/* <div className=" pl-0 pr-0 header_center text-center">
                                 <h3><a href="#">{localStorage.getItem('screenname')}</a></h3>
                              </div> */}

                              <div className="header_right pl-0">
                                 <ul className="uld">
                                    {/* <li><Link to={'/EditProfile'} onClick={this.setStorage.bind(this)} className="link" data-toggle="tab"><img src="images/editUser.png" align="img"/></Link></li> */}
                                    {this.state.userDetails && this.state.userDetails?.schedule ?
                                       <li className="more link abort" >
                                          <Link to={`/external-link?url=${modifyHref(this.state.userDetails?.schedule)}`}>
                                             <DepartSvg />
                                             <span className='lowercase-text' >{modifyWebsiteName(this.state.userDetails?.schedule)}{modifyWebsiteName(this.state.userDetails?.schedule)?.endsWith("/") && <label className="hdots">...</label>}</span>
                                          </Link>
                                       </li> : <li className="more abort" ></li>
                                    }
                                    <li className='bg-none'><Link to={'/saved'} className="link" data-toggle="tab" onClick={this.setStorage.bind(this)}><img className='right-arrow' src="images/Settings_directive.png" align="img" /></Link></li>
                                 </ul>
                              </div>
                           </div>
                        </section>
                        {/* <!-- --------- userpoint ---------------> */}
                        {/*  <section className={this.userpoint()}>
         <div className="test">  
            {localStorage.getItem('profileImage')!="" ? <img src={this.state.baseUrl+localStorage.getItem('profileImage')} align="images"/> : <span className="usernamecl" id="diffrent">{localStorage.getItem("staticname")}</span> }
            <ul>
               <li><a href="#"><NumberFormat value={localStorage.getItem('totalpost')} displayType={'text'} thousandSeparator={true}/> <span class="namespan">Posts</span></a></li>
               <li><Link to={'/Myfollower'} onClick={this.setStorage.bind(this)} className="link"  data-toggle="tab" ><NumberFormat value={localStorage.getItem('followers')} displayType={'text'} thousandSeparator={true}/><span class="namespan">Followers</span></Link></li>
               <li><Link to={'/Myfollowing'} onClick={this.setStorage.bind(this)} className="link" data-toggle="tab"><NumberFormat value={localStorage.getItem('following')} displayType={'text'} thousandSeparator={true}/><span class="namespan">Following</span></Link></li>
            </ul>
         </div>
{localStorage.getItem('bio')!=="null" && localStorage.getItem('bio')!=="" ? <h3 dangerouslySetInnerHTML={{__html: this.fetchafterfilter(localStorage.getItem('bio'))}}></h3> : "" }
         {localStorage.getItem("website") !=="null" ? <p class={localStorage.getItem('bio')=="null" || localStorage.getItem('bio')=="" ? "webp":""}><a href={"https://"+localStorage.getItem("website")}>{localStorage.getItem("website")}</a></p> :<p><a></a></p> }
         <p><a href="#">{localStorage.getItem("website") !="null"? localStorage.getItem("website") ? localStorage.getItem("website") : <span></span>: ""}</a></p>
      </section> */}
                     </div>
                     <div className="tab-content tab-content1">
                        {/* <!--------------- sectionone ------------------> */}
                        <div id="onedate" className="tab-pane fade active show">
                           <div className="flex-center">
                              {
                                 (() => {
                                    if (!Object.keys(this.state.userDetails)?.length) {
                                       return null;
                                    }

                                    const beforeActiveSubTimetables = [];
                                    const mainTimetables = ['current', 'next', 'twoMonthsAhead', 'threeMonthsAhead', 'previous', 'twoMonthsEarlier', 'threeMonthsEarlier']
                                       .flatMap((month) => {
                                          const monthData = this.state.timetableData[month];
                                          const subTimetables = monthData?.subTimetables;

                                          if (subTimetables && Object.keys(subTimetables)?.length > 0) {
                                             const entries = Object.entries(subTimetables);
                                             const isCurrentMonth = month === "current";
                                             let activeAndAfter = entries.filter(([_, subData]) => subData.isVisible);
                                             let beforeActive = [];



                                             if (isCurrentMonth) {
                                                const activeIndex = entries.findIndex(([_, subData]) => subData.isActive);

                                                activeAndAfter = activeIndex !== -1
                                                   ? entries.slice(activeIndex).filter(([_, subData]) => subData.isVisible)
                                                   : [];

                                                beforeActive = activeIndex !== -1
                                                   ? entries.slice(0, activeIndex).filter(([_, subData]) => subData.isVisible)
                                                   : entries.filter(([_, subData]) => subData.isVisible);

                                                beforeActive.forEach(([subKey, subData], index) => {

                                                   beforeActiveSubTimetables.push(
                                                      <Timetable
                                                         key={`${month}-${subKey}-beforeActive`}
                                                         selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                                         isScreenVisible={this.props.isScreenVisible}
                                                         subspaceNumber={1}
                                                         calendar={`${month}-${subKey}`}
                                                         userDetails={this.state.userDetails}
                                                         timetableData={subData}
                                                         states={{
                                                            events: this.state.marks,
                                                            otherMinimum: this.state.minimum,
                                                            otherMaximum: this.state.maximum,
                                                            otherMonthYear: this.state.monthyear,
                                                         }}
                                                         updateTimetableData={(data) => this.updateTimetableData(data, month, subKey)}
                                                         updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                                         updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                                         editDeletePost={(e, post) => this.editdeletepost(e, post, month, subKey)}
                                                      />
                                                   );

                                                });
                                             }

                                             return activeAndAfter.map(([subKey, subData]) => (
                                                <Timetable
                                                   key={`${month}-${subKey}`}
                                                   selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                                   isScreenVisible={this.props.isScreenVisible}
                                                   subspaceNumber={1}
                                                   calendar={`${month}-${subKey}`}
                                                   userDetails={this.state.userDetails}
                                                   timetableData={subData}
                                                   states={{
                                                      events: this.state.marks,
                                                      otherMinimum: this.state.minimum,
                                                      otherMaximum: this.state.maximum,
                                                      otherMonthYear: this.state.monthyear,
                                                   }}
                                                   updateTimetableData={(data) => this.updateTimetableData(data, month, subKey)}
                                                   updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                                   updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                                   editDeletePost={(e, post) => this.editdeletepost(e, post, month, subKey)}
                                                />
                                             ));
                                          } else {
                                             return (
                                                <Timetable
                                                   key={month}
                                                   selfProfile={localStorage.getItem('token') && localStorage.getItem('userid')}
                                                   isScreenVisible={this.props.isScreenVisible}
                                                   subspaceNumber={1}
                                                   calendar={month}
                                                   userDetails={this.state.userDetails}
                                                   timetableData={monthData}
                                                   states={{
                                                      events: this.state.marks,
                                                      otherMinimum: this.state.minimum,
                                                      otherMaximum: this.state.maximum,
                                                      otherMonthYear: this.state.monthyear,
                                                   }}
                                                   updateTimetableData={(data) => this.updateTimetableData(data, month)}
                                                   updateStatesFromTimetable={(data) => this.updateStatesFromTimetable(data)}
                                                   updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, month)}
                                                   editDeletePost={(e, post) => this.editdeletepost(e, post, month)}
                                                />
                                             );
                                          }
                                       });                                     

                                    return [...mainTimetables, ...beforeActiveSubTimetables];
                                 })()}
                           </div>
                        </div>
                        <div id="onelist" className="tab-pane fade">
                           <div class={this.state.evntData.length > 0 ? "loaderclass closeCdff" : "loaderclass onscroll"}><img src="images/newgif.gif" alt="loading" /></div>
                           {/* <div class={this.state.contat == "of" ? "loaderclass contantf closeCd" : "loaderclass contantf"}>No More post Available</div> */}
                           <div className={"sectionone changable" + ((!this.state.cbio || !this.state.cwebsite) ? " emptyBioWebsite" : "")}>
                              <div
                                 id="scrollableDivOneFourProfile"
                                 className={this.state.evntData.length > 0 ? this.state.loaddocument == true ? "scrollClass newscreen1 " : "scrollClass newscreen1" : "scrollClass newscreen1"}
                                 style={{
                                    overflow: 'auto',
                                    display: 'flex !important',
                                    flexDirection: 'row',
                                 }}
                              >
                                 {(this.state.updateTemporalInformation || !this.state.updateTemporalInformation) && 
                                 <InfiniteScroll
                                    className={this.state.evntData.length > 0 ? "topbuttom_scroll" : ""}
                                    dataLength={this.state.evntData.length}
                                    style={{ display: 'flex', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    hasMore={true}
                                    onScroll={this.onscrolldocument.bind(this)}

                                    loader={
                                       <div className={`loaderclass ${this.state.inverse ? "topscrolls" : "bottomscrolls"} ${this.state.loaddocument ? "" : "closeCd"}`}>
                                          <img
                                             src="images/newgif.gif"
                                             alt="loading"
                                          />


                                       </div>}
                                    scrollableTarget="scrollableDivOneFourProfile"
                                 >
                                    <div className="mb-2 timeline-wrapper">
                                       {this.state.evntData.map((j, i) => {
                                          return (
                                             <div className="timeline-event-box">
                                                <span id={'hashme' + j.eventID} className={j.eventID}></span>


                                                <div id={"crox" + j.eventID} className="postlike" onDoubleClick={this.likepost.bind(this)}>
                                                   <div className="userin rmrf">
                                                      {/* <a href="#">{j.profileImage!="" ? <img src={this.state.baseUrl+j.profileImage} align="images"/> : <span className="username">{localStorage.getItem("staticname")}</span> }</a> */}
                                                      <h1 className='new-heading-tex-780' dangerouslySetInnerHTML={{ __html: formatDate(getdateformat(j.postedOn, j.postedOn2, j.timezone)) }}></h1>
                                                      <h5>
                                                         {/* {j?.link ? (
                                                            <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                                               <div className='userLinkWrapper'>
                                                               <img src="../../images/UD.png"></img>
                                                               <div className="UserLinkDetails">
                                                                  <p><span className='userLinkSource'>Source:</span> {j?.source}</p>
                                                                  <p className='userLinkMessage'>Verify the correctness of temporal information via the original source, if you plan to act on it</p>
                                                               </div>
                                                               </div>
                                                            </a>
                                                         ) : ''} */}
                                                         {j?.link ? (
                                                            <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                                               <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 1) }} />
                                                            </a>
                                                            ) : ( 
                                                               <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status, 1) }} />
                                                         )}
                                                      </h5>
                                                      {/* {(() => {
                                                         const regex = /(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/ig;
                                                         const text = j.status;
                                                         const replaced2 = text.replace(regex, value => {
                                                            const link = getLinkElement(value)
                                                            return `<a href=${"https://" + value} target='_blank'>${link}</a>`
                                                         });
                                                         const regex2 = /!+([a-zA-Z0-9_]+)/ig;
                                                         const replaced3 = replaced2.replace(regex2, value => `<span>${value}</span>`);
                                                         const replaced = replaced3.replace(/(?:\r\n|\r|\n)/g, '<br>');
                                                         return (<h5 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(j.status) }}>
                                                         </h5>);
                                                      })()}  */}
                                                      {this.state.cbio && this.state.cbio !== null && this.state.cbio !== "" ? <h3 dangerouslySetInnerHTML={{ __html: this.fetchafterfilter(this.state.cbio) }}></h3> : ""}
                                                      {this.state.cwebsite ? <p class={this.state.cbio == null || this.state.cbio == "" ? "webp" : "cv"}><a href={"https://" + this.state.cwebsite}>{this.state.cwebsite ? this.state.cwebsite : ""}</a></p> : ""}
                                                   </div>
                                                   <div className="post ">
                                                      {(() => {
                                                         if (j.mediaType == "video") {
                                                            return (
                                                               <div id={'Video' + j.eventID} class="video-div">
                                                                  <CustomVideoPlayer
                                                                     ref={(el) => (this[`ref${i}`] = el)}
                                                                     type="video/mp4"
                                                                     src={this.state.baseUrl+j.eventImage}
                                                                     autoPlay={false}
                                                                     onClose={() => {}}
                                                                     className={"video-react-has-started"}
                                                                     onPause={() => {}}
                                                                     onRewind={() => this.changeCurrentTimeNew(-10, i)}
                                                                     onForward={() => this.changeCurrentTimeNew(10, i)}
                                                                  />
                                                                  {/* <Player
                                                                     ref={player => {
                                                                        this[`ref${i}`] = player;
                                                                     }}
                                                                     autoPlay={false}
                                                                     className="video-react-has-started"
                                                                     controls={false}
                                                                     playsInline
                                                                     disablePictureInPicture
                                                                     >
                                                                     <source src={this.state.baseUrl + j.eventImage} type="video/mp4" />
                                                                     <ControlBar disableDefaultControls={true} autoHide={true} className="my-class" />
                                                                  </Player> */}
                                                                  {/* <ul class="videoulkap pb-3">
                                                                     <li><Button data-id={"player" + j.id} className={-10} onClick={() => this.changeCurrentTimeNew(-10, i)} ><img class="img1" src="images/Backwards.png" />
                                                                        <span>10</span></Button>
                                                                     </li>
                                                                     <li><img class="img2" src="images/pause.png" /></li>
                                                                     <li><Button data-id={j.id} className={10} onClick={() => this.changeCurrentTimeNew(10, i)} ><img class="img3" src="images/Forwards.png" />
                                                                        <span>10</span></Button>
                                                                     </li>
                                                                  </ul> */}
                                                               </div>);
                                                         }
                                                         else if (j.mediaType == "image") {
                                                            return (
                                                               <img className="w-100 mt-1" src={this.state.baseUrl + j.eventImage} alt="image" />
                                                            );
                                                         }
                                                      }
                                                      )()}
                                                      <div className="hearti">
                                                         <img className="w-100" src="images/cklick.png" alt="image" />
                                                      </div>
                                                   </div>
                                                </div>
                                                <ul className={j.mediaType == null ? "videoul mt3 status" : "videoul mt3"} >
                                                   <li>
                                                      {/* {(() => { 
if(j.commentCounts>0)
{

return( <a className="link link2k" id={j.eventID} data-toggle="tab" >< Link to={{
   pathname:`/SinglePage/${j.eventID}`,
state: {back:"front",image:localStorage.getItem('profileImage'),name:localStorage.getItem('screenname'),username:localStorage.getItem('username')}
 }} onClick={this.setStorage.bind(this)} >
<img src="images/comment.png" Style="height:20px;"
alt="icon"/> <NumberFormat value={j.commentCounts} displayType={'text'} thousandSeparator={true}/></Link></a>)
}
else
{
return(<a className="link" id={j.eventID}  data-toggle="tab" ><Link  to={{
   pathname:`/SinglePage/${j.eventID}`,
state: {back:"front",image:localStorage.getItem('profileImage'),name:localStorage.getItem('screenname'),username:localStorage.getItem('username')}
 }} onClick={this.setStorage.bind(this)}>
<img src="images/comment.png" Style="height:20px;"alt="icon"/></Link></a>)
}})()} */}
                                                      <a className="upbutton" id={j.eventID} onClick={this.editdeletepost.bind(this)}><img className="option ml-3" src="images/pencil.png"
                                                         align="icon" /></a>
                                                   </li>
                                                   {(() => {
                                                      if (j.withwho != null) {
                                                         return (<li><img src="images/b.png" alt="icon" />
                                                            {(() => {
                                                               if (j.withwho != null) {
                                                                  return (
                                                                     j.withwho.map((k, l) => {
                                                                        return (
                                                                           <span><a href="#"><Link to={{
                                                                              pathname: `/${k.username}`,
                                                                              state: { id: k.user_id }
                                                                           }} onClick={this.setStorage.bind(this)}
                                                                           >{k.screenName}</Link></a><span>, </span></span>
                                                                        );
                                                                     })
                                                                  )
                                                               }
                                                               else {
                                                                  return (
                                                                     <a href="#"></a>
                                                                  )
                                                               }
                                                            }
                                                            )()}
                                                         </li>
                                                         )
                                                      }
                                                   }
                                                   )()}
                                                   {(() => {
                                                      if (j.likedByUsers.length > 0) {
                                                         return (<li>
                                                            <img src="images/c.png" alt="icon" />
                                                            {(() => {
                                                               if (j.likedByUsers.length > 0) {
                                                                  return (
                                                                     j.likedByUsers.map((q, t) => {
                                                                        return (<span><a href="#"><Link to={{
                                                                           pathname: `/${q.username}`,
                                                                           state: { id: q.user_id }
                                                                        }} onClick={this.setStorage.bind(this)}
                                                                        >{q.screenName}</Link></a><span>, </span></span>
                                                                        );
                                                                     })
                                                                  )
                                                               }
                                                            }
                                                            )()}
                                                         </li>
                                                         )
                                                      }
                                                   }
                                                   )()}
                                                   {(() => {
                                                      if (j.likedByUsers.length > 0) {
                                                         return (<li>
                                                            <img src="images/d.png" alt="icon" />
                                                            {(() => {
                                                               if (j.commentByUsers.length > 0) {
                                                                  return (
                                                                     j.commentByUsers.map((m, index) => {
                                                                        return (
                                                                           <span><a href="#"><Link to={{
                                                                              pathname: `/${m.username}`,
                                                                              state: { id: m.user_id }
                                                                           }} onClick={this.setStorage.bind(this)}
                                                                           >{m.screenName}</Link></a><span>, </span></span>
                                                                        );
                                                                     })
                                                                  )
                                                               }
                                                            }
                                                            )()}
                                                         </li>
                                                         )
                                                      }
                                                   }
                                                   )()}
                                                </ul>
                                             </div>)

                                       }
                                       )
                                       }</div>
                                 </InfiniteScroll>}
                              </div>
                           </div>
                        </div>
                     </div>
                     <Footer {...this.props} />
                     <div className="op pupup2">
                        <div className="upbutton22 h-100"></div>
                        <ul>
                           <li><Link to={'/edit/' + this.state.eventid} onClick={this.setStorage.bind(this)} className="upbutton22 link" id={this.state.eventid} ><img src="images/edt.png" /> Edit</Link></li>
                           <li><a className="upbutton22" onClick={() => this.setState({ openPopup : true })}><img src="images/dlt.png" /> Delete</a></li>
                           <button className="upbtn upbutton22">Cancel</button>
                        </ul>
                     </div>
                     {this.state.openPopup && (
                     <CustomPopUp
                        isOpen={this.state.openPopup}
                        onClose={() => this.setState({ openPopup: false })}
                        popup={{
                           heading: "Temporal information deletion",
                           message: `Are you sure you would like to delete this temporal information, ${localStorage.getItem('screenname').split(" ")[0] + '?'}`,
                           onClick: () => {
                              this.submitdelete()
                           },
                        }}
                        multipleButton={true}
                     />
                     )}
                  </div>




                  : <><div className="screen1 tab-pane fade in active" id="onelogin">
                     <div className="top newbg" id="myHeader">
                        {/* header */}
                        <section className="header userlogin">
                           <div className="row">
                              <div className="col-12 pl-0 pr-0 header_center text-center">
                                 <h6>Timesort</h6>
                              </div>
                           </div>
                        </section>
                     </div>
                     <form className="form-signin login">
                        <h3>Login</h3>
                        <div className="form-group">
                           <label>username or email</label>
                           <input type="text" name="email" id="inputEmail" value={this.state.email}
                              onChange={this.handleChangeEmail.bind(this)} className="form-control" placeholder="username or email" required="" />
                        </div>
                        <div className="form-group mb-0">
                           <label>password</label>
                           <input type="password" name="password" id="inputPassword" value={this.state.password} onChange={this.handleChangePassword.bind(this)} className="form-control" placeholder="password" required="" />
                        </div>
                        <p className="mb-2 text-right"><a className="link" data-toggle="tab" href="#oneforgot">forgot password?</a></p>
                        <button className="btn btn-lg btn-block" onClick={this.onClick.bind(this)} type="button">Login</button>
                        <h6 className="text-center"><Link to={'/SignUp'} className="link" data-toggle="tab" >Sign Up</Link></h6>
                     </form>
                  </div></>}
            {/* </div>  */}
         </>
      )
   }
};
Myprofile.contextType = MyContext;
export default Myprofile;