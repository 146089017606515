export function handleDateDifferenceContainer(event, subspace) {
    const targetedElement = event.target;
    let elementX = null;
    let elementY = null;
    let tooltipWidth = null;
    let container = null;

    if (targetedElement?.classList?.contains('date-diff-tooltip1') || targetedElement?.classList?.contains('date-diff-tooltip2')) {
        const parentElement = targetedElement?.parentElement?.parentElement?.parentElement;

        if (targetedElement?.classList?.contains('date-diff-tooltip1')) {
            const elementRect = targetedElement?.getBoundingClientRect();
            tooltipWidth = targetedElement?.offsetWidth;
            elementX = elementRect?.left + window?.scrollX;
            elementY = elementRect?.top + window?.scrollY - 338;
        } else if (targetedElement?.classList?.contains('date-diff-tooltip2')) {
            const elementRect = targetedElement?.getBoundingClientRect();
            tooltipWidth = targetedElement?.offsetWidth;
            elementX = elementRect?.left + window?.scrollX;
            elementY = elementRect?.top + window?.scrollY - 338;
        }

        const adjustArrowPosition = (value, container) => {
            container.style.setProperty('--before-date-difference-left', `${value}`);
            // container.style.setProperty('--after-date-difference-left', `${value}`);
        }

        if (parentElement?.classList?.contains('new-heading-tex-789') || parentElement?.classList?.contains('new-heading-tex-780')) {
            const container = parentElement?.nextElementSibling;
            if (subspace == 1 && !targetedElement?.classList?.contains('date-diff-tooltip2') && elementX < 200) {
                adjustArrowPosition(`${(tooltipWidth / 2) + 17}px`, container);
                container.style.left = `${elementX}px`;
            } else {
                adjustArrowPosition(`${54}%`, container);
                container.style.left = `${(elementX - 192.5) + (tooltipWidth / 2)}px`;
            }
            container.style.top = `${elementY}px`;
        }
    }
}

export const DateDifferenceContainer = () => {
    return (
        <>
            <div className="date-difference-container">
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Reference</th>
                            <th></th>
                            <th>Date difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>“<span className="word-group">last</span>" &middot;<span className="katex-math math-symbol"> w</span></td>
                            <td>iff</td>
                            <td><span className="katex-math">d</span> <span className="element-of-symbol">&#8712;</span> [&ndash;<span className="word-group">7</span>, &ndash;<span className="word-group">2</span>] <span className="intersect">∩</span> <span className="katex-ams">Z</span><sup>&ndash;</sup></td>
                        </tr>
                        <tr>
                            <td>“<span className="word-group">yesterday</span>"  &middot;<span className="katex-math math-symbol"> ϵ</span></td>
                            <td>iff</td>
                            <td><span className="katex-math">d</span> = &ndash;<span className="word-group">1</span></td>
                        </tr>
                        {/* <tr>
                            <td>“<span className="word-group">today</span>"</td>
                            <td>iff</td>
                            <td><span className="katex-math">d</span> = <span className="word-group">0</span></td>
                        </tr> */}
                        <tr>
                            <td>“<span className="word-group">tomorrow</span>"  &middot;<span className="katex-math math-symbol"> ϵ</span></td>
                            <td>iff</td>
                            <td><span className="katex-math">d</span> = <span className="word-group">1</span></td>
                        </tr>
                        <tr>
                            <td>“<span className="word-group">this</span>"  &middot;<span className="katex-math math-symbol"> w</span></td>
                            <td>iff</td>
                            <td><span className="katex-math">d</span> <span className="element-of-symbol">&#8712;</span> [<span className="word-group">2</span>, <span className="word-group">6</span>] <span className="intersect">∩</span> <span className="katex-ams">N</span><sup>+</sup></td>
                        </tr>
                        <tr>
                            <td>“<span className="word-group">next</span>"  &middot;<span className="katex-math math-symbol"> w</span></td>
                            <td>iff</td>
                            <td><span className="katex-math">d</span> = <span className="word-group">7</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
