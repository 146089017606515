export function handleGrammarContainer(event, subspace, tab) {
    if (window?.innerWidth < 767) {
        return;
    }
    const targetedElement = event.target;
    const nextElement = targetedElement.nextElementSibling;
    const parentElement = targetedElement.parentNode;
    const container = nextElement.querySelector('.grammar-container');
    const elementRect = targetedElement?.getBoundingClientRect();
    let elementX = elementRect?.left + window?.scrollX;

    elementX = window.innerWidth < 600 ? elementX - 169 : window.innerWidth < 767 ? elementX - 194 : window.innerWidth < 991 ? elementX - 233 : elementX - 297;
    // const elementY = elementRect?.top + window?.scrollY - 440;
    const elementBottomY = window.innerHeight - (elementRect?.top + window?.scrollY) + 90;

    const adjustArrowPosition = (value) => {
        container.style.setProperty('--before-grammer-indicator-left', `${value}%`);
        container.style.setProperty('--transform-grammer-indicator-before', `${-value}%`);
    }

    if (container) {
        if ((subspace === 1 && tab === 1) && ((window.innerWidth >= 1350 && window.innerWidth <= 1774) || (window.innerWidth >= 2150 && window.innerWidth <= 2500))) {
            container.style.left = `${elementX + 60}px`;
            container.style.bottom = `${elementBottomY}px`;
            adjustArrowPosition(41);
        } else if ((subspace === 2 && tab === 3) && (window.innerWidth >= 1350 && window.innerWidth <= 1774)) {
            container.style.left = `${elementX - 25}px`;
            container.style.bottom = `${elementBottomY}px`;
            adjustArrowPosition(57);
        } else {
            container.style.left = `${elementX}px`;
            container.style.bottom = `${elementBottomY}px`;
        }
        container.style.display = 'block';
        nextElement.style.display = 'block';
        parentElement.style.zIndex = '3';
    } 
}

export const getGrammarContainer = (folder, tab, stackLength) => {
    return (
        <>
            <div class="grammar-heading"><p><span className='katex-SansSerif'>Doc</span> <span className='mathcal'>D</span> <span class="mrel">::=</span> <span className='type-writer'>color</span> <span className='text-bf'>of</span> <span class="katex-math">&alpha;</span> <span className='small-text'>(<span className='text-gyre'>abstract</span>)</span></p></div>
            <div class="grammar-table">
                <div class="indent katex-font"><p>| <span className='type-writer'>coordinate</span> <span className='text-bf'>of</span> <span><span className='katex-ams'>N</span><sup>+</sup></span> &times; <span><span className='katex-ams'>N</span><sup>+</sup></span></p></div>
                <div class="indent katex-font"><p>| <span className='type-writer'>point</span> <span className='text-bf'>of</span> <span className='type-writer'>color</span> &times; <span className='type-writer'>coordinate</span></p></div>
                <div class="indent katex-font"><p>| <span className='type-writer'>row</span> <span className='text-bf'>of</span> <span className='type-writer'>point seq</span></p></div>
                <div class="indent katex-font"><p>| <span className='type-writer'>subspace</span> <span className='text-bf'>of</span> <span className='type-writer'>row seq</span> <span class="small-text">(<span className='text-gyre'>{stackLength} on ladder {tab} of folder {folder}</span>)</span></p></div>
                <div class="indent katex-font"><p>| <span className='type-writer'>ladder</span> <span className='text-bf'>of</span> <span className='type-writer'>subspace stack</span></p></div>
                <div class="indent katex-font"><p>| <span className='type-writer'>folder</span> <span className='text-bf'>of</span> <span className='type-writer'>ladder seq</span></p></div>
                <div class="indent katex-font"><p>| <span className='type-writer'>document</span> <span className='text-bf'>of</span> <span className='type-writer'>folder seq</span></p></div>
                <div class="indent katex-font"><p>| <span className='type-writer'>documentV2</span> <span className='text-bf'>of</span> <span class="font-normal">(<span class="katex-math">&alpha;</span> &times; (<span className='katex-ams'>N</span><sup>+</sup> &times; <span className='katex-ams'>N</span><sup>+</sup>))</span><span className='type-writer'>seq seq stack seq seq</span></p></div>
            </div>
            <div className='grammar-variables-table'>
                <div className='variables-name'>
                    <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>adheres</span></p>
                    <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>populated</span></p>
                    <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>separated</span></p>
                    <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>transparent</span></p>
                    <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>flexible</span></p>
                    <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>layered</span></p>
                    <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>member</span></p>
                </div>
                <div className='variables-value'>
                    <p>well-formed, syntactically</p>
                    <p>subspaces atop active ladders together exhaust space</p>
                    <p>subspaces of distinct folders are non-overlapping</p>
                    <p>if a subspace is visible, then it is visible in its entirety</p>
                    <p>different No. of folders with different width, possibly</p>
                    <p>subspaces in same folder comprise same space</p>
                    <p>membership in language <span className='mathcal'>L</span><sup className='katex-SansSerif fw-700'>DPD</sup></p>
                </div>
            </div>
            <div className='grammar-expression'>
                <p>
                    <span className='mathcal'>D</span> <span className='katex-SansSerif'>adheres</span> <span className='mathcal'>D</span> <span className='katex-SansSerif'>populated</span> <span className='mathcal'>D</span> <span className='katex-SansSerif'>separated</span> <span className='mathcal'>D</span> <span className='katex-SansSerif'>transparent</span> <span className='mathcal'>D</span> <span className='katex-SansSerif'>flexible</span> <span className='mathcal'>D</span> <span className='katex-SansSerif'>layered</span>
                </p>
                <p><span className='mathcal'>D</span> <span className='katex-SansSerif'>member</span></p>
            </div>
            <div className='triangle-down'></div>
        </>
    );
}

export const removeGrammarContainer = (event) => {
    const targetedElement = event.target;
    const container = targetedElement.querySelector('.grammar-container');
    const parentElement = targetedElement.parentNode;
    if(targetedElement && container) {
        container.style.removeProperty('display');
        targetedElement.style.removeProperty('display');
        parentElement.style.removeProperty('z-index');
    }
}