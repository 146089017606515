import { showInitialOfFullName } from "../profiles/shared";

export function handleTooltipContainer(event, subspaceNumber) {
    if (window?.innerWidth < 767) {
        return;
    }

    const target = event?.target;
    let lowestRange = Infinity;
    let highestRange = 0;

    const container = target?.querySelector('.tooltip-container');
    const profileContainer = container?.querySelector('.profiles-container');
    const profileColumns = profileContainer?.querySelectorAll('.profile-column-view');
    const rangeBorder = container?.querySelector('.common-range-border');
    const rangeSpan = container?.querySelector('.org-range');

    const firstColumnLastElement = profileColumns[0]?.lastElementChild;
    const lastColumnLastElement = profileColumns[profileColumns.length - 1]?.lastElementChild;
    const firstColumnLastElementRect = firstColumnLastElement?.getBoundingClientRect();
    const lastColumnLastElementRect = lastColumnLastElement?.getBoundingClientRect();
    const firstColumnLastElementLeft = firstColumnLastElementRect?.left;
    const lastColumnLastElementRight = lastColumnLastElementRect?.right;

    const tooltipContainerRect = container?.getBoundingClientRect();
    const distanceFromLeft = firstColumnLastElementLeft - tooltipContainerRect.left;
    const distanceFromRight = tooltipContainerRect.right - lastColumnLastElementRight;

    profileColumns.forEach((column, index) => {
        const orgNames = column?.querySelectorAll('.org-name');
        const length = orgNames.length;

        // remove all existing spans from each orgName
        orgNames.forEach(orgName => {
            orgName.querySelectorAll('span').forEach(span => span.remove());
        });

        lowestRange = Math.min(lowestRange, length); // Update lowestRange
        highestRange = Math.max(highestRange, length); // Update highestRange

        for (let i = 0; i < length - 1; i++) {
            const currentOrgName = orgNames[i];
            const nextOrgName = orgNames[i + 1];
            const currentWidth = currentOrgName.offsetWidth;
            const nextWidth = nextOrgName.offsetWidth;

            // Create a span for numbering
            let numberSpan = document.createElement('span');
            numberSpan.textContent = i + 1;

            if (currentWidth > nextWidth) {
                currentOrgName.style.borderBottom = '1px solid #000';
                numberSpan.style.bottom = '-13px';
                currentOrgName.appendChild(numberSpan);
            } else //if (currentWidth < nextWidth) 
            {
                nextOrgName.style.borderTop = '1px solid #000';
                numberSpan.style.top = '-12px';
                nextOrgName.appendChild(numberSpan);
            }
        }
    });

    const totalBorderLength = (container?.offsetWidth) - (distanceFromLeft + distanceFromRight);
    if (rangeBorder && lowestRange && highestRange) {
        rangeBorder.style.width = `${totalBorderLength}px`;
        rangeBorder.style.marginLeft = `${distanceFromLeft - 30}px`;
        if (lowestRange === highestRange) {
            rangeSpan.innerHTML = lowestRange;
            rangeSpan.style.right = '-16px';
        } else {
            rangeSpan.innerHTML = `${lowestRange}&ndash;${highestRange}`;
            rangeSpan.style.right = '-34px';
        }
    }

    // now we apply the random css to position the tooltip
    const elementRect = target.getBoundingClientRect();
    const elementX = elementRect?.left + window?.scrollX;
    const elementX2 = window?.innerWidth - elementX;
    // const elementY = elementRect?.top + window?.scrollY;

    const adjustArrowPositionFromLeft = (value) => {
        container.style.setProperty('--before-tooltip-container-left', `${value}`);
        // container.style.setProperty('--transform-tooltip-container-before', `-${value}`);
    }

    const adjustArrowPositionFromRight = (value) => {
        container.style.setProperty('--before-tooltip-container-left', 'auto');
        container.style.setProperty('--transform-tooltip-container-before', 'auto');
        container.style.setProperty('--before-tooltip-container-right', `${value}`);
    }

    const tooltipWidth = container?.offsetWidth;
    if (container) {
        const leftCutOff = elementX - (tooltipWidth) / 2;
        const rightCutOff = elementX2 - (tooltipWidth) / 2;
        const toBeMove = 60;
        if (subspaceNumber === 1) {
            if (leftCutOff > 50) {
                container.style.left = `${leftCutOff}px`;
            } else {
                container.style.left = `${toBeMove}px`;
                adjustArrowPositionFromLeft(`${(elementX - toBeMove + 5)}px`);
            }
        } else if (subspaceNumber === 2) {
            if (window?.innerWidth > 2149) {
                container.style.left = `${elementX - (tooltipWidth / 2)}px`;
            } else {
                if (rightCutOff > 50) {
                    container.style.right = `${rightCutOff}px`;
                } else {
                    container.style.right = `${toBeMove}px`;
                    container.style.left = 'auto';
                    adjustArrowPositionFromRight(`${(elementX2 - toBeMove) - 15}px`);
                }
            }
        } else {
            if (rightCutOff > 50) {
                container.style.right = `${rightCutOff}px`;
            } else {
                container.style.right = `${toBeMove}px`;
                container.style.left = 'auto';
                adjustArrowPositionFromRight(`${(elementX2 - toBeMove) - 15}px`);
            }
        }
    }
}

export const getTooltipContainer = (tabwiseStackInfo) => {
    return (
        <>
            <div className="profiles-container">
                {Object.values(tabwiseStackInfo).map((stackInfo, stackInd) => {
                    if (stackInfo.organizations?.length) {
                        return (
                            <div className='profile-column-view'>
                                {
                                    stackInfo.organizations.map((org, ind) => {
                                        return (
                                            <p className='org-name' num={ind}>
                                                {org?.screenName}
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                })}
            </div>
            <div className="common-range-container">
                <div className="common-range-border"></div>
                <span className="org-range"></span>
            </div>
        </>
    )
}

export const getDualTooltipContainer = (dataFromFirst, dataFromSecond) => {
    let laddersLength = null;
    if (!dataFromFirst || !dataFromSecond) {
        return;
    } else {
        laddersLength = Object.keys(dataFromFirst).length + Object.keys(dataFromSecond).length;
    }

    const hasValidData = (data) => data && Object.keys(data).length > 0;
    const haveOneFolder = hasValidData(dataFromFirst) || hasValidData(dataFromSecond);
    const haveTwoFolder = hasValidData(dataFromFirst) && hasValidData(dataFromSecond);

    const orderedKeys =
        localStorage.getItem("profileType") !== 'consumer'
            ? ['first', 'second', 'third']
            : ['second', 'third'];



    const renderProfileData = (data) => {
        const abbreviateNames = laddersLength >= 5;
        return hasValidData(data) && orderedKeys.map((key) => {
            if (data[key]) {
                return (
                    <div className='profile-column-view'>
                        {data[key].map((org) => (
                            <p className='org-name'>{abbreviateNames && org?.length > 20 ? showInitialOfFullName(org?.screenName) : org?.screenName}</p>
                        ))}
                    </div>
                )
            }
        })
    };

    return (
        <>
            <div className="dual-tooltip-container">
                {hasValidData(dataFromFirst) && <div id='1' className="profiles-container-wrapper">
                    <div className="profiles-container">
                        {renderProfileData(dataFromFirst)}
                    </div>
                    <div className="common-range-container">
                        <div className="common-range-border"></div>
                        <span className="org-range"></span>
                    </div>
                </div>}
                {hasValidData(dataFromSecond) && <div id='2' className="profiles-container-wrapper">
                    <div className="profiles-container">
                        {renderProfileData(dataFromSecond)}
                    </div>
                    <div className="common-range-container">
                        <div className="common-range-border"></div>
                        <span className="org-range"></span>
                    </div>
                </div>}
            </div>
            {haveTwoFolder ? (
                <div className="common-folder-container">
                    <div className="folder-border">
                        <p></p>
                        <p></p>
                    </div>
                </div>
            ) : haveOneFolder ? (
                <div className="common-folder-container">
                    <div className="folder-border">
                        <p></p>
                    </div>
                </div>
            ) : ('')}
        </>
    );
}

export function handleDualTooltipContainer(event, differentiatorPosition) {
    const target = event?.target;
    let folderBorderWidth = 0;
    let firstProfilesContainerWidth = 0;

    const targetedRect = target.getBoundingClientRect();
    const elementX = targetedRect.left + window.screenX;

    const mainContainer = target.querySelector('.tooltip-container');
    const container = target?.querySelector('.dual-tooltip-container');
    const tooltipWidth = container?.offsetWidth;
    const profileContainerWrapper = container?.querySelectorAll('.profiles-container-wrapper');

    profileContainerWrapper.forEach((containerWrapper, index) => {
        let lowestRange = Infinity;
        let highestRange = 0;
        const profileContainer = containerWrapper?.querySelector('.profiles-container');
        const profileColumns = profileContainer?.querySelectorAll('.profile-column-view');

        const rangeBorder = containerWrapper?.querySelector('.common-range-border');
        const rangeSpan = containerWrapper?.querySelector('.org-range');
        let secondRangeBorderFromLeft = 0;

        profileColumns.forEach((column, ind) => {
            const orgNames = column?.querySelectorAll('.org-name');
            const length = orgNames.length;

            // remove all existing spans from each orgName
            orgNames.forEach((orgName, idx) => {
                orgName.querySelectorAll('span').forEach(span => span.remove());
                orgName.style.cssText = '';
                if (index === 1 && ind === 0 && idx === orgNames?.length - 1) {
                    const computedStyle = getComputedStyle(orgName, null);
                    secondRangeBorderFromLeft = computedStyle['margin-left'];
                }
            });

            lowestRange = Math.min(lowestRange, length); // Update lowestRange
            highestRange = Math.max(highestRange, length); // Update highestRange

            for (let i = 0; i < length - 1; i++) {
                const currentOrgName = orgNames[i];
                const nextOrgName = orgNames[i + 1];
                const currentWidth = currentOrgName.offsetWidth;
                const nextWidth = nextOrgName.offsetWidth;

                // Create a span for numbering
                let numberSpan = document.createElement('span');
                numberSpan.textContent = i + 1;

                if (currentWidth > nextWidth) {
                    currentOrgName.style.borderBottom = '1px solid #000';
                    numberSpan.style.bottom = '-13px';
                    currentOrgName.appendChild(numberSpan);
                } else //if (currentWidth < nextWidth) 
                {
                    nextOrgName.style.borderTop = '1px solid #000';
                    numberSpan.style.top = '-12px';
                    nextOrgName.appendChild(numberSpan);
                }
            }
        })

        if (rangeBorder && lowestRange && highestRange) {
            const firstColumnLastElement = profileColumns[0]?.lastElementChild;
            const lastColumnLastElement = profileColumns[profileColumns.length - 1]?.lastElementChild;
            const firstColumnLastElementRect = firstColumnLastElement?.getBoundingClientRect();
            const lastColumnLastElementRect = lastColumnLastElement?.getBoundingClientRect();
            const firstColumnLastElementLeft = firstColumnLastElementRect?.left;
            const lastColumnLastElementRight = lastColumnLastElementRect?.right;

            const tooltipContainerRect = container?.getBoundingClientRect();
            const distanceFromLeft = firstColumnLastElementLeft - tooltipContainerRect.left;
            const distanceFromRight = tooltipContainerRect.right - lastColumnLastElementRight;

            const totalBorderLength = (container?.offsetWidth) - (distanceFromLeft + distanceFromRight);
            rangeBorder.style.width = `${totalBorderLength}px`;
            if (index === 0) {
                firstProfilesContainerWidth = profileContainer?.offsetWidth;
                rangeBorder.style.marginLeft = `${distanceFromLeft}px`;
            } else {
                rangeBorder.style.marginLeft = `${secondRangeBorderFromLeft}`;
            }
            if (lowestRange === highestRange) {
                rangeSpan.innerHTML = lowestRange;
                rangeSpan.style.right = '-16px';
            } else {
                rangeSpan.innerHTML = `${lowestRange}&ndash;${highestRange}`;
                rangeSpan.style.right = '-34px';
            }
        }
    });

    const folderContainer = mainContainer?.querySelector('.common-folder-container');
    const folderBorder = folderContainer?.querySelector('.folder-border');
    const folderNameTag = folderBorder?.querySelectorAll('p');
    const activeFolder = differentiatorPosition === 1 && profileContainerWrapper[0]?.id === '1' ? 'Folder 1' :
        differentiatorPosition === 1 && profileContainerWrapper[0]?.id === '2' ? 'Folder 2' :
            differentiatorPosition === 2 && profileContainerWrapper[0]?.id === '1' ? 'Folder 2' : 'Folder 3';

    if (folderContainer && folderBorder) {
        folderContainer.style.marginLeft = `${(firstProfilesContainerWidth / 2) - (58 / 2)}px`;
        folderBorder.style.borderBottom = '1px solid #000';

        profileContainerWrapper.forEach((containerWrapper) => {
            const folderWidth = containerWrapper.offsetWidth;
            folderBorderWidth = folderBorderWidth + (folderWidth / 2);
        })

        if (folderNameTag?.length === 2) {
            folderContainer.style.width = `${folderBorderWidth + 58 + 28}px`;
            if (differentiatorPosition === 1) {
                folderNameTag[0].innerHTML = 'Folder 1';
                folderNameTag[1].innerHTML = 'Folder 2';
            } else if (differentiatorPosition === 2) {
                folderNameTag[0].innerHTML = 'Folder 2';
                folderNameTag[1].innerHTML = 'Folder 3';
            }
        } else if (folderNameTag?.length === 1) {
            folderContainer.style.width = `60px`;
            folderNameTag[0].innerHTML = activeFolder;
        }
    }
    mainContainer.style.left = `${elementX - (container?.offsetWidth / 2)}px`;
}

export function handleOrganizationTree(event, subspaceNumber, isHomePage = false) {
    const width = window?.innerWidth;

    if ((isHomePage && (width < 1179 || width > 1459)) || (!isHomePage && (width < 1500 || width > 1775)))
        return;


    let lowestRange = Infinity;
    let highestRange = 0;

    const tooltipWidth = event?.offsetWidth;
    const tooltipHeight = event?.offsetHeight;
    const profileContainer = event?.querySelector('.org-container');
    const profileColumns = profileContainer?.querySelectorAll(".column-view");
    const rangeBorder = event?.querySelector('.common-range-border');
    const rangeSpan = event?.querySelector('.org-range');

    if (!profileColumns?.length) {
        setTimeout(() => {
            handleOrganizationTree(event, subspaceNumber)
        }, 1);
    }

    const firstColumnLastElement = profileColumns[0]?.lastElementChild;
    const lastColumnLastElement = profileColumns[profileColumns.length - 1]?.lastElementChild;
    const firstColumnLastElementRect = firstColumnLastElement?.getBoundingClientRect();
    const lastColumnLastElementRect = lastColumnLastElement?.getBoundingClientRect();
    const firstColumnLastElementLeft = firstColumnLastElementRect?.left;
    const lastColumnLastElementRight = lastColumnLastElementRect?.right;


    const tooltipContainerRect = event?.getBoundingClientRect();
    const distanceFromLeft = firstColumnLastElementLeft - tooltipContainerRect.left;
    const distanceFromRight = tooltipContainerRect.right - lastColumnLastElementRight;

    const totalBorderLength = tooltipWidth - (distanceFromLeft + distanceFromRight);

    profileColumns.forEach((column, index) => {
        const orgNames = column?.querySelectorAll('.org-name');
        const length = orgNames.length;

        // remove all existing spans from each orgName
        orgNames.forEach(orgName => {
            orgName.querySelectorAll('span').forEach(span => span.remove());
        });

        lowestRange = Math.min(lowestRange, length); // Update lowestRange
        highestRange = Math.max(highestRange, length); // Update highestRange

        for (let i = 0; i < length - 1; i++) {
            const currentOrgName = orgNames[i];
            const nextOrgName = orgNames[i + 1];
            const currentWidth = currentOrgName.offsetWidth;
            const nextWidth = nextOrgName.offsetWidth;

            let numberSpan = document.createElement('span');
            numberSpan.textContent = i + 1;

            if (currentWidth > nextWidth) {
                currentOrgName.style.borderBottom = '1px solid #000';
                numberSpan.style.bottom = '-12px';
                currentOrgName.appendChild(numberSpan);
            } else {
                nextOrgName.style.borderTop = '1px solid #000';
                numberSpan.style.top = '-12px';
                nextOrgName.appendChild(numberSpan);
            }
        }
    });

    if (rangeBorder && lowestRange && highestRange) {
        rangeBorder.style.width = `${totalBorderLength - 2}px`;
        rangeBorder.style.marginLeft = `${distanceFromLeft}px`;
        if (lowestRange === highestRange) {
            rangeSpan.innerHTML = lowestRange;
        } else {
            rangeSpan.innerHTML = `${lowestRange}&ndash;${highestRange}`;
        }
    }
}