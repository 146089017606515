import React, { createContext, useContext, useState } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [sharedData, setSharedData] = useState({ freshLoad: true, authUser: null, lastViewedUser: null, pageWiseData: {}, tabWiseData: {}});
  const [activeTab, setActiveTab] = useState("second");
  const [searching, setSearching] = useState(false);
  const [lastOpenedPages, setLastOpenedPages] = useState({
    first: "",
    second: "",
    third: "",
  });

  return (
    <MyContext.Provider value={{ sharedData, setSharedData, activeTab, setActiveTab, searching, setSearching, setLastOpenedPages, lastOpenedPages }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);

export {MyContext}
